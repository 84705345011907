import React, { Component } from 'react'
import queryString from 'query-string'
import API from "../../../shared/api";
import { Container, Row, Col, Card } from 'react-bootstrap';
import {API_PATH} from "../../App/Constants"

export default class AccountActivation extends Component {
    constructor(props) {
          super(props);
          this.state = {
            token: "",
            verified: false,
            errorMessage: ""
          }
    }
    
    componentDidMount() {
      // const { match: { params } } = this.props;
      const values = queryString.parse(this.props.location.search);
      this.setState({token:values.token})

      console.log(this.state.token);
      console.log(this.state.verified);
      API.post(API_PATH.activate_account,{token: values.token}           
        ).then((response)=>{
            this.setState({
              verified :true}
            )

        }).catch(error=>{
            this.setState({errorMessage:error.response.data})
        })
        
    }
    continue = e => {
      window.location.replace("/");
    }
    successmessage = () => {
      return (
          <div>
              <h1 className="">Congratulations! Your Account has been verified.<br /> Move forward to Login.</h1>
              
          </div>
        )
    }

    failMessage = () => {
      return (
            <div>
              <h1 className="text-danger"> {this.state.errorMessage}</h1>
            </div>
        )
    }
    render() {
      let message = ''
      // 
        if(this.state.verified===true) {
            message = this.successmessage();
            // {window.setTimeout(this.continue, 5000)}
        } else {
          message = this.failMessage();
        }
        return (
              <Container fluid>
                <Container>
                  <Card className="mt-5">
                    <Card.Body>
                      {message}
                    </Card.Body>
                  </Card>
                </Container>
              </Container>
            )        
    }
}
