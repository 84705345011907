import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
//import { Test } from './MyAccount.styles';
import Sidebar from '../Sidebar'
import { Container, Row, Col, Tabs,Tab, Card, Form, Button, InputGroup } from 'react-bootstrap';
import {API_PATH} from "../../App/Constants"
import API from "../../../shared/api";
import NoImage from '../../../assets/img/no-img.png'
import SharedServices from "../../../shared/Services"
import { ToastContainer, toast } from 'react-toastify';
import './MyAccount.scss'
import { IconContext } from "react-icons";
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import DeleteAccount from "../DeleteAccount"
import CommunicationPreferences from "../CommunicationPreferences"
// import styles from './PasswordField.less';
class MyAccount extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
      logged_in : localStorage.getItem(API_PATH.token_name) ? true : false,
      user_detail:{first_name:"",last_name:"",profiles:{}},
      validated:false,
      password_validated:false,
      update_fields:{},
      errors:{},
      new_password_type: "password",
      new_password_eye:<FaRegEye width="2" />,
      old_password_eye:<FaRegEye />,
      old_password_type: "password",
      preferences:{}
    };
  }
  componentDidMount = () => {
    API.get(API_PATH.user_detail, {})
    .then(res => {
      if(!res.data.profiles.country_isd_code) {
        res.data.profiles.country_isd_code = "+91";
      }
      this.setState({
        user_detail : res.data,
        preferences:{email:res.data.is_email_subscribed?1:0,loi:"1-100"}
        
      })
      // localStorage.setItem("_o_u",JSON.stringify(res.data.user))
    })
    .catch(err => {
      if(err.response.status == 401) {
        SharedServices.goToHome()
      }
    });
  }
  
  handleChange = (event) => {
    let fields = this.state.update_fields;
    event.target.value = event.target.value.trimLeft();
    fields[event.target.name] = event.target.value;
    this.setState({
      fields
    });
  };
  activateTab =(ev,event) => {
    this.setState({update_fields:{},password_validated: false,validated: false})
  }
  validate = () => {
    let errors = {}
    if(!this.state.update_fields.old_password) {
      errors['old_password'] = "This is a required field"
    }
    if(!this.state.update_fields.new_password) {
      errors['new_password'] = "This is a required field"
    }
    const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,25}$/;
    if(this.state.update_fields.new_password && this.state.update_fields.confirm_password) {
      if (!re.test(this.state.update_fields.new_password) ) {
        errors['new_password'] = "password must contain 8-25 characters.contains at least a Special character, upper and lower case letters and a number, and must not contain spaces or emoji.";
      }
      this.setState({
          errors,
        });
      if(Object.keys(errors).length > 0){
          return false;
      }
    }
    return true
  };
  erroMessages = (messages) => {
    let msg = [];
    for (let i = 0; i < messages.length; i++) {
      msg.push(messages[i]['message']);
    }
    return msg.join(".");
  };
  handleSubmit = (ev,formName) => {
    ev.preventDefault()
    let isValid = true
    let api_path = API_PATH.update_user_info
    
    if(formName == "change_password") {
      isValid = this.validate();
      api_path = API_PATH.change_password
    }
    const form = ev.currentTarget;
    
    if (form.checkValidity() === true && isValid && Object.keys(this.state.update_fields).length>0) {
      
      API.put(api_path, this.state.update_fields)
      .then(res => {
        if(formName == "change_password") {
          localStorage.setItem(API_PATH.token_name,res.data.token.access);
          localStorage.setItem(API_PATH.refresh_token_name,res.data.token.refresh);
          toast.success("Password changed successfully!!", { autoClose: 15000, position: toast.POSITION.TOP_RIGHT });
        }
        else {
          let user_info = this.state.user_detail
          if(this.state.update_fields.first_name) {
            user_info.first_name = this.state.update_fields.first_name
          }
          if(this.state.update_fields.last_name) {
            user_info.last_name = this.state.update_fields.last_name
          }
          // user_info.last_name = this.state.update_fields.last_name
          this.setState({user_info})
          toast.success("User info updated successfully!!", { autoClose: 5000, position: toast.POSITION.TOP_RIGHT });
        }
        this.setState({update_fields:{}})
        // localStorage.setItem("_o_u",JSON.stringify(res.data.user))
      })
      .catch(err => {
        if(err.response.status == 401) {
          SharedServices.goToHome()
        }
        else {
          if(Object.keys(err.response.data).length > 0 && err.response.data.validation_error) {
            let validation_error = err.response.data.validation_error;
            let errors = {}
            Object.entries(validation_error).forEach(([key, value]) => errors[key]=this.erroMessages(value));
            this.setState({
              errors,
            });
          }
          else {
            toast.error("Please try again later!!", { autoClose: 5000, position: toast.POSITION.TOP_RIGHT });
          }
        }
      });
    }
    // let isValid = this.validate();
    if(formName == "account") {
      this.setState({ validated: true });
    }else {
      this.setState({ password_validated: true });
    }
    
  }

  toggleNewPasswordVisiblity = (e) => {
    e.preventDefault();
    this.setState({
      new_password_type:this.state.new_password_type == 'text' ? 'password' : 'text',
      new_password_eye:this.state.new_password_type == 'text' ? <FaRegEye /> : <FaRegEyeSlash />
    });
  };

  toggleOldPasswordVisiblity = (e) => {
    e.preventDefault();
    this.setState({
      old_password_type:this.state.old_password_type == 'text' ? 'password' : 'text',
      old_password_eye:this.state.old_password_type == 'text' ? <FaRegEye /> : <FaRegEyeSlash />
    });
  };
  componentWillUnmount() {
      // fix Warning: Can't perform a React state update on an unmounted component
      this.setState = (state,callback)=>{
          return;
      };
  }

  updatePreference = (name,value) => {
    console.log("update" + name + value)
    if(this.state.preferences.hasOwnProperty(name)) {
      let new_preferences = { ...this.state.preferences, [name]: value}
      this.setState({preferences:new_preferences})
    }
  }
  render () {
    if(this.state.loggedIn === false){
          return <Redirect to="/"/>
    }
    let modalHide = () => this.setState({ modalShow: false });
    return (
      <div className="MyAccountWrapper">
        <Container fluid className="border-bottom border-top">
					<Row>
						<Sidebar />
						<Col>
              <Row className="mt-3">
                <Col sm={3} className="">
                  <Card>
                    <Card.Header style={{backgroundColor:"#a494e3"}} className="text-center">
                      <img src={NoImage} className="w-25 border rounded-circle p-1" />
                      <div className="mt-3">
                        <p>{this.state.user_detail.first_name} {this.state.user_detail.last_name}</p>
                      </div>
                      
                    </Card.Header>
                    {/* <Card.Body>
                      Body
                    </Card.Body> */}
                  </Card>
                </Col>
                <Col sm={8} className="mt-2 mb-2">
                  <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" onSelect={this.activateTab}>
                    <Tab eventKey="home" title="My Account">
                      <Form 
                        onSubmit={event=>this.handleSubmit(event,"account")}
                        noValidate
                        validated={this.state.validated}
                        className="mt-2">
                        <Form.Row>
                          <Form.Group as={Col} sm={6} controlId="formGridEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control name="email" required type="email" placeholder="Enter Email" readOnly defaultValue={this.state.user_detail.email} />
                          </Form.Group>
                          <Form.Group as={Col} controlId="formPhone">
                            <Form.Label>Phone</Form.Label>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text>{this.state.user_detail.profiles.country_isd_code}</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control 
                                type="text"
                                maxLength="20"
                                pattern="[0-9]{10,20}"
                                title="Phone number should be a numeric value with minimum 10 character long."
                                name="phone" 
                                placeholder="Enter Phone" 
                                onChange={this.handleChange}
                                defaultValue={this.state.user_detail.phone} 
                              />
                              </InputGroup>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} sm={6} controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              required 
                              type="text"
                              name="first_name"
                              maxLength="50"
                              placeholder="Enter First Name"
                              onChange={this.handleChange}
                              defaultValue={this.state.user_detail.first_name} 
                            />
                          </Form.Group>
                          <Form.Group as={Col} controlId="formLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control 
                              type="text"
                              name="last_name"
                              maxLength="50"
                              onChange={this.handleChange}
                              placeholder="Last Name" 
                              defaultValue={this.state.user_detail.last_name}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} sm={6} controlId="formGender">
                            <Form.Label>Gender</Form.Label>
                            <Form.Control required type="text" className="" readOnly defaultValue={this.state.user_detail.profiles.gender} />
                          </Form.Group>
                          <Form.Group as={Col} controlId="formDOB">
                            <Form.Label>Date Of Birth</Form.Label>
                            <Form.Control required type="text" placeholder="Date Of Birth" className="" readOnly defaultValue={this.state.user_detail.profiles.dob} />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formLanguage">
                            <Form.Label>Language</Form.Label>
                            <Form.Control required type="text" placeholder="Enter Gender" className="" readOnly defaultValue={this.state.user_detail.profiles.language} />
                          </Form.Group>
                          <Form.Group as={Col} controlId="formCountry">
                            <Form.Label>Country</Form.Label>
                            <Form.Control required type="text" placeholder="Country" readOnly defaultValue={this.state.user_detail.profiles.country} />
                          </Form.Group>
                          <Form.Group as={Col} sm={6} controlId="formPostalcode">
                            <Form.Label>Postal Code/ Zip Code</Form.Label>
                            <Form.Control 
                              required 
                              type="text"
                              minLength="3"
                              maxLength="20"
                              pattern={this.state.user_detail.profiles.zipcode_regex} 
                              placeholder="Enter Postal Code / Zip Code" 
                              name="postal_code" 
                              onChange={this.handleChange} 
                              defaultValue={this.state.user_detail.profiles.postal_code} />
                          </Form.Group>
                        </Form.Row>
                        <Button variant="primary" type="submit">
                          Save Changes
                        </Button>
                        &nbsp;
                        <Button variant="danger" onClick={() => this.setState({ modalShow: true })} type="button">
                          Delete Account
                        </Button>
                      </Form>
                    </Tab>
                    {/* <Tab eventKey="profile" title="My Address">
                      Hello 2
                    </Tab> */}
                    <Tab eventKey="contact" title="Change Password">
                      <Form
                        onSubmit={event=>this.handleSubmit(event,"change_password")}
                        // noValidate
                        // validated={this.state.password_validated}
                        className="mt-2"
                      >
                        <Form.Row>
                          <Form.Group as={Col} sm={12} controlId="formOldPassword">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control required type="password" onChange={this.handleChange} name="old_password" placeholder="Enter Old Password" className="" />
                            <Form.Text className="text-danger">
                              {this.state.errors.old_password}
                            </Form.Text>
                          </Form.Group>
                          <Form.Group sm={12} as={Col} controlId="formNewPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                              required
                              // type="password"
                              type={this.state.new_password_type} 
                              // ref={ref => { this.input = ref; }}
                              minLength="6"
                              maxLength="25"
                              name="new_password"
                              onChange={this.handleChange}
                              placeholder="Enter New Password"
                              className=""
                              aria-describedby="passwordHelpBlock"
                            />
                            <i className="showHideEye" onClick={this.toggleNewPasswordVisiblity}>
                            <IconContext.Provider  value={{ color: "black" }}>
                              {this.state.new_password_eye}
                              </IconContext.Provider>
                            </i>
                            {/* <Form.Text id="passwordHelpBlock" muted>
                              Your password must contain 8-25 characters.contains at least a Special character, upper and lower case letters and a number, and must not contain spaces or emoji.
                            </Form.Text> */}
                            <Form.Text className="text-danger">
                              {this.state.errors.new_password}
                            </Form.Text>
                          </Form.Group>
                          <Form.Group sm={12} as={Col} controlId="formConfirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control 
                              required 
                              type={this.state.old_password_type} 
                              // ref={ref => { this.input = ref; }}
                              // type="password"
                              minLength="6"
                              maxLength="25"
                              name="confirm_password"
                              placeholder="Enter Confirm Password"
                              onChange={this.handleChange}
                              className="" 
                              isInvalid={this.state.update_fields.new_password && this.state.update_fields.new_password !== this.state.update_fields.confirm_password}
                            />
                            <i className="showHideEye" onClick={this.toggleOldPasswordVisiblity}>
                            {this.state.old_password_eye}
                            </i>
                            <Form.Text className="text-danger">
                              {this.state.errors.confirm_password}
                            </Form.Text>
                          </Form.Group>
                        </Form.Row>
                        <Button variant="primary" type="submit">
                          Change Password
                        </Button>
                      </Form>
                    </Tab>
                    {this.state.user_detail.is_email_verified ?
                      (<Tab eventKey="communication_preferences" title="Preferences">
                        <CommunicationPreferences preferences={this.state.preferences} update_preference={this.updatePreference} />
                      </Tab>):("")
                    }

                  </Tabs>
                </Col>
              </Row>							
						</Col>
					</Row>
				</Container>
        <DeleteAccount
          show={this.state.modalShow}
          onHide={modalHide}
        ></DeleteAccount>
      </div>
    );
  }
}

MyAccount.propTypes = {
  // bla: PropTypes.string,
};

MyAccount.defaultProps = {
  // bla: 'test',
};

export default MyAccount;
