/*
* @Author: pradeep kumar baranwal
* @Date:   2020-12-04 00:49:46
* @Last Modified by:   pradeep kumar baranwal
* @Last Modified time: 2020-12-22 15:43:59
*/
import React from 'react'
import Header from '../../Header'
import Footer from '../../Footer'
import './Joinus.scss'
import { NavLink } from 'react-router-dom'
import backImage from '../../../assets/img/Background.jpeg'
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import API from "../../../shared/api";
import LoginForm from "../LoginForm";
import {API_PATH} from "../../App/Constants"
import { css } from "@emotion/react";
import LoadingOverlay from 'react-loading-overlay'
import {ClockLoader} from "react-spinners";
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import $ from 'jquery';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


var sectionStyle = {
    // backgroundColor: '#82bb41',
    backgroundImage: `url(${backImage})`,
    
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  overflow:hidden;
`;

class JoinUs extends React.Component {
    // state = initialState;
    constructor(props) {
        super(props);
        let start_date = new Date();
        start_date.setFullYear(start_date.getFullYear()-13);
        
        // start_date = start_date.toISOString().slice(0, 10)
        
        this.state = {
            fields : {
                // First Name of Respondent
                first_name: "",
                // Last Name of Respondent
                last_name: "",
                // Repondent Email field
                email: "",
                dob: null,
                password: "",
                password2: "",
                postal_code: "",
                gender: "",
                country: "",
                language: "",
                is_agree_tnc: "",
            },
            // fields : {
            //     // First Name of Respondent
            //     first_name: "test",
            //     // Last Name of Respondent
            //     last_name: "test",
            //     // Repondent Email field
            //     email: "test@test.com",
            //     dob: new Date("02/01/2008"),
            //     password: "123456",
            //     password2: "123456",
            //     postal_code: "123",
            //     gender: "m",
            //     country: "38",
            //     language: "1",
            //     is_agree_tnc: false,
            // },
            // userId: "",
            errors: {},
            zipcode_regex:"",
            countries:[],
            languages:[],
            emErrors: "",
            validated:false,
            modalShow: false,
            loading: false,
            maxDate:start_date
        };
    }

    componentDidMount = () =>{
      $(".react-datepicker-wrapper").addClass("col-sm-12 p-0")
      API.get(API_PATH.country, {})
        .then(res => {
          
          this.setState({
              countries:res.data
          })        
        })
        .catch(err => {
          // alert("Please try again later!!");
        });
      API.get(API_PATH.language, {})
        .then(res => {
          
          this.setState({
            languages:res.data
          })        
        })
        .catch(err => {
          // alert("Please try again later!!");
        })
    }



    setDOB = (date) => {
      let fields = this.state.fields
      fields["dob"] = date
      this.setState(fields)
    };

    setZipCodeRegEx = (country_id) => {
      console.log(country_id);
      this.state.countries.map((country,i)=>{
        if(country.id == country_id) {
          this.setState({zipcode_regex:country.zipcode_regex})
        }
      })
      console.log(this.state.zipcode_regex)
    };
    handleChange = (event) => {
        let fields = this.state.fields;
        const isCheckbox = event.target.type === "checkbox";
        let value;
        if(isCheckbox) {
          value = event.target.checked
        }
        else {
          value = event.target.value.trimLeft()
        }
        fields[event.target.name] = value;
        if(event.target.name == 'country' && event.target.value) {
          this.setZipCodeRegEx(event.target.value)
        }
        this.setState({
          fields
        });
    };
    validate = () => {
        let errors = {}
        //email check regex
        const rxEmail = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        this.setState({emailInput : String(this.state.fields['email']).toLowerCase().trim()});
        if (!rxEmail.test(this.state.fields['email'])) {
          errors["email"] = "invalid email";
        }
        if ( this.state.fields.password.length < 6 || this.state.fields.password.length > 25 ) {
          errors['password'] = "password must contain 6-25 characters";
        } else if (this.state.fields.password !== this.state.fields.password2) {
            errors['password2'] = "Password and Confirm Password should be same";
        }

        var regDOB = /^([12]\d{3})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
        if(!this.state.fields.dob) {
          errors['dob'] = "This field is required";
        }
        else if(!this.state.fields.dob instanceof Date) {
          errors['dob'] = "Invalid date of birth";
        }
        if(this.state.zipcode_regex) {
          var zipregex = new RegExp(this.state.zipcode_regex);
          if(!zipregex.test(this.state.fields.postal_code)) {
            errors['postal_code'] = "Postal code / Zip is invalid";
          }
        }
        this.setState({
            errors,
          });
        if(Object.keys(errors).length > 0){
            return false;
        }
        return true
    };
    addDays = (year) =>{
      let start_date = new Date();
      start_date.setFullYear(start_date.getFullYear()-year);
      return start_date
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        let isValid = this.validate();
        if (form.checkValidity() === true && isValid) {
          
          let data = Object.assign({}, this.state.fields);
          
          data["dob"] = data["dob"].toISOString().split('T')[0]
          this.setState({loading:true})
          API.post(API_PATH.register_user, data)
          .then((response) => {
              let data = response.data
              localStorage.setItem(API_PATH.token_name, data.token.access);
              localStorage.setItem(API_PATH.refresh_token_name, data.token.refresh);
              localStorage.setItem("_o_u", JSON.stringify(data.user));
              window.location.replace("/");
              this.setState({loading:false})
            
          })
          .catch((error) => {
              let errorData = error.response.data
              if(errorData.hasOwnProperty('validation_error')) {
                  let error_messages = errorData.validation_error
                  let errors = {}
                  for(let i in error_messages) {
                      errors[i] = ""
                      for(let m in error_messages[i]) {
                          if(i==='email' && error_messages[i][m]["code"] === 'unique') {
                              error_messages[i][m]["message"] = "email already exists.";
                          }
                          errors[i]+=error_messages[i][m]["message"]
                      }
                  }
                  this.setState({loading:false})
                  this.setState({errors:errors})
              }
          });

            event.target.password.value = "";
            // event.target.is_agree_tnc.checked = false;
        }
        else{
            isValid = true
        }
        
        this.setState({ validated: true });
    };

    range = (start, end) => {
      let ans = [];
      for (let i = start; i <= end; i++) {
          ans.push(i);
      }
      return ans;
    }
  
    // <abbr className="text-danger" title="This field is required" aria-label="required">*</abbr>
    render() {
        let modalHide = () => this.setState({ modalShow: false });
        const logged_in = localStorage.getItem(API_PATH.token_name) ? true : false;

        if(logged_in) {
          return <Redirect to="/" />
        }
        return (
            <div className="JoinUs">
                <LoadingOverlay
                  active={this.state.loading}
                  spinner={<ClockLoader
                      css={override}
                      size={150}
                      color={"#123abc"}
                      
                    />}
                />
                <Container fluid style={sectionStyle}>
                    <Container className="d-block pt-5 pb-5">
                        <Row>
                            <Col sm={8}  className="m-auto">
                                <Card className="shadow">
                                    <Card.Header className="text-center text-light font-weight-bolder bg-primary">Join Our Panel</Card.Header>
                                    <Card.Body>
                                      <Form
                                        onSubmit={this.handleSubmit}
                                        style={{lineHeight: "1rem" }}
                                        noValidate 
                                        validated={this.state.validated}
                                      >
                                        <Form.Group as={Row}>
                                          <Col md={6} sm={12}>
                                            <Form.Label htmlFor="first_name">First Name</Form.Label>

                                            <Form.Control 
                                              required
                                              size="sm"
                                              name="first_name"
                                              id="first_name"
                                              type="text"
                                              minLength="4"
                                              maxLength="100"
                                              placeholder="First Name"
                                              value={this.state.fields.first_name}
                                              onChange={this.handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              This field is required.
                                            </Form.Control.Feedback>
                                          </Col>
                                          <Col md= {6} sm={12} className="onus-pt-sm-2">
                                            <Form.Label htmlFor="last_name">Last Name</Form.Label>
                                            <Form.Control 
                                              size="sm"
                                              sm={6}
                                              name="last_name"
                                              minLength="4"
                                              maxLength="100"
                                              id="last_name"
                                              type="text"
                                              placeholder="Last Name"
                                              value={this.state.fields.last_name}
                                              onChange={this.handleChange}
                                            />
                                          </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                          <Col md={6} sm={12}>
                                            <Form.Label htmlFor="email">Email address</Form.Label>
                                            <Form.Control 
                                              required
                                              size="sm"
                                              name="email"
                                              id="email"
                                              maxLength="100"
                                              type="email"
                                              placeholder="Enter email"
                                              value={this.state.fields.email}
                                              onChange={this.handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              Please provide a valid email.
                                            </Form.Control.Feedback>
                                            <Form.Text className="text-danger">
                                              {this.state.errors.email}
                                            </Form.Text>
                                          </Col>
                                          <Col md={6} sm={12} className="onus-pt-sm-2">
                                            <Form.Label htmlFor="dob">Date of Birth</Form.Label>
                                            <DatePicker
                                              required 
                                              className="form-control form-control-sm"
                                              portalId="onus-pradee"
                                              id="dob"
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              value={this.state.fields.dob}
                                              selected={this.state.fields.dob} 
                                              onChange={date => this.setDOB(date)}
                                              dateFormat="yyyy-MM-dd"
                                              minDate={this.addDays(100)}
                                              maxDate={this.addDays(13)}
                                             />
                                            <Form.Text className="text-danger">
                                              {this.state.errors.dob}
                                            </Form.Text>
                                          </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                          <Col md={6} sm={12}>
                                            <Form.Label htmlFor="password">Password</Form.Label>
                                            <Form.Control
                                              required
                                              type="password"
                                              size="sm"
                                              name="password"
                                              id="password"
                                              minLength="6"
                                              maxLength="25"
                                              placeholder="Password"
                                              value={this.state.fields.password}
                                              onChange={this.handleChange}
                                              autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                            {this.state.errors.password}
                                            </Form.Control.Feedback>
                                          </Col>
                                          <Col md={6} sm={12} className="onus-pt-sm-2">
                                            <Form.Label htmlFor="password2">Confirm Password</Form.Label>
                                            <Form.Control
                                              required
                                              type="password"
                                              size="sm"
                                              minLength="6"
                                              maxLength="25"
                                              name="password2"
                                              id="password2"
                                              placeholder="Confirm Password"
                                              value={this.state.fields.password2}
                                              onChange={this.handleChange}
                                              isInvalid={this.state.password && this.state.password !== this.state.password2}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              This field is required.
                                            </Form.Control.Feedback>
                                            <Form.Text className="text-danger">
                                              {this.state.errors.password2}
                                            </Form.Text>
                                          </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                          <Col md={6} sm={12} className="onus-pt-sm-2">
                                            <Form.Label htmlFor="language">Language</Form.Label>
                                            <Form.Control custom
                                              required
                                              as="select"
                                              size="sm"
                                              name="language"
                                              id="language"
                                              value={this.state.fields.language}
                                              onChange={this.handleChange}
                                            >
                                                <option value="">Select Language</option>
                                                {this.state.languages.map((lang,i)=><option value={lang.id} key={i}>{lang.language_name}</option>)}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                              This field is required.
                                            </Form.Control.Feedback>
                                          </Col>
                                          <Col md={6} sm={12}>
                                            <Form.Label htmlFor="country">Country</Form.Label>
                                            <Form.Control
                                              required
                                              as="select"
                                              size="sm"
                                              name="country"
                                              id="country"
                                              value={this.state.fields.country}
                                              onChange={this.handleChange}
                                              autoComplete="off"
                                            >
                                                <option value="">Select Country</option>
                                                {this.state.countries.map((cntr,i)=><option value={cntr.id} key={i}>{cntr.country_name}</option>)}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                              This field is required.
                                            </Form.Control.Feedback>
                                          </Col>
                                          
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                          <Col md={6} sm={12} className="onus-pt-sm-2">
                                            <Form.Label htmlFor="gender">Gender</Form.Label>
                                            <Form.Control custom
                                              required
                                              as="select"
                                              size="sm"
                                              name="gender"
                                              id="gender"
                                              placeholder="Gender"
                                              value={this.state.fields.gender}
                                              onChange={this.handleChange}
                                            >
                                                <option value="">Select Gender</option>
                                                <option value="m">Male</option>
                                                <option value="f">Female</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                              This field is required.
                                            </Form.Control.Feedback>
                                          </Col>
                                          <Col md={6} sm={12}>
                                            <Form.Label htmlFor="postal_code">Postal Code / ZIP </Form.Label>
                                            <Form.Control
                                              required
                                              type="text"
                                              size="sm"
                                              minLength="3"
                                              maxLength="20"
                                              name="postal_code"
                                              id="postal_code"
                                              pattern={this.state.zipcode_regex}
                                              placeholder="Postal Code / ZIP"
                                              value={this.state.fields.postal_code}
                                              onChange={this.handleChange}
                                              autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              {this.state.errors.postal_code}
                                            </Form.Control.Feedback>
                                          </Col>
                                          
                                        </Form.Group>
                                        <Form.Group controlId="is_agree_tnc">
                                            <Form.Check type="checkbox" id="is_agree_tnc">
                                                <Form.Check.Input required className="mt-0" type="checkbox" name="is_agree_tnc" onChange={this.handleChange} />
                                                <Form.Check.Label htmlFor="is_agree_tnc">I have read the <NavLink target="_blank" to="/terms-and-condition/">terms and condition</NavLink> and <NavLink target="_blank" to="/privacy-policy/">privacy policy</NavLink>.</Form.Check.Label>
                                                <Form.Control.Feedback type="invalid">
                                                  You must agree before submitting.
                                                </Form.Control.Feedback>
                                            </Form.Check>
                                        </Form.Group>
                                        <Row className="border-top pt-2">
                                            <Col sm={12} className="text-center">
                                                <Button type="submit" className="btn btn-success">
                                                  Register
                                                </Button>
                                            </Col>
                                            <Col className="text-md-right pt-1">
                                                Already Have an Account? <span onClick={() => this.setState({ modalShow: true })} className="text-info" style={{cursor:"pointer"}}>Login</span>
                                            </Col>
                                        </Row>
                                      </Form>
                                    </Card.Body>
                                  </Card>
                            </Col> 
                        </Row>
                    </Container>
                </Container>
                <LoginForm
                  show={this.state.modalShow}
                  onHide={modalHide}
                ></LoginForm>
            </div>
        )
    }

}

export default JoinUs;

