import React, { PureComponent } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import Sidebar from '../Sidebar'
import { FaCodeBranch, FaDesktop, FaMobile, FaMobileAlt, FaTablet, FaTabletAlt, FaArrowAltCircleRight, FaRegFrown } from "react-icons/fa";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './MySurveys.scss'
import {API_PATH} from "../../App/Constants"
import API from "../../../shared/api";
import backImage from '../../../assets/img/Background.jpeg'
import SharedServices from "../../../shared/Services"
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { css } from "@emotion/react";
import LoadingOverlay from 'react-loading-overlay'
import {ClockLoader} from "react-spinners";

//import { Test } from './MySurveys.styles';

var sectionStyle = {
  // backgroundColor: '#82bb41',
  backgroundImage: `url(${backImage})`,
  
}

const override = css`
display: block;
margin: 0 auto;
border-color: red;
overflow:hidden;
`;
class MySurveys extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      devices_map:{"desktop":<FaDesktop title="Available on Desktop!" key="desktop" />,"tablet":<FaTabletAlt title="Available on Tablet!" key="tablet" />,"phone":<FaMobileAlt title="Available on Mobile!" key="phone" />},
      survey_data : [],
      calling_remote:true,
      loading:false,
    };
  };

  goToSurvey=(ev,survey_id)=>{
    this.setState({loading:true});
    SharedServices.goToSurvey(survey_id);
    setTimeout(() => this.setState({ loading: false }),4000);
  }

  componentDidMount = () => {
    API.get(API_PATH.survey, {})
    .then(res => {
      this.setState({
        survey_data : res.data.data.surveys,
        calling_remote:false,
      })
    })
    .catch(err => {
      if(err.response.status == 401) {
        SharedServices.goToHome()
      }
      this.setState({calling_remote:false,})
    });
  }
  
  componentWillUnmount() {
      // fix Warning: Can't perform a React state update on an unmounted component
      this.setState = (state,callback)=>{
          return;
      };
  }
  surveysList = () => {
    return (this.state.survey_data.map((obj,i)=><Col sm={6} md={6} lg={4} className="mb-3" key={i}>
      <Card className="">
        <Card.Header>
          <Row>
            <Col>
              <h5 className="survey-name">{obj.id}</h5>
            </Col>
            <Col className="text-right">
              {obj.devices.map((d,j)=>this.state.devices_map[d])}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <Row>
            <Col sm={6}>
              <p className="point bg-success">{obj.points} <small>Points</small></p>
            </Col>
            <Col sm="1" xs={2}></Col>
            <Col className="text-center pt-2 pb-1">
              <span style={{width:"35%"}} className="d-block p-1" >
                <CircularProgressbar value={obj.loi} maxValue="60" className="font-weight-bold" text={obj.loi}
                styles={{text: {
                  // Text color
                  // fill: '#f88',
                  // Text size
                  fontSize: '30px',
                  fontWeight:"bold"
                },}}
                />
                <span>LOI</span>
              </span>
              
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col sm={5} xs={6}>
              <i className="fa fa-clock-o"></i> Live Since {obj.live_since}
            </Col>
            <Col sm={7} xs={6}>
              <input type="button" name="Take Survey" value="Take Survey" onClick={(ev)=>this.goToSurvey(ev,obj.id)} className="btn btn-sm btn-default text-primary pull-right" />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Col>))
  }

  render () {
    let survey_list = this.surveysList()
    if(this.state.calling_remote) {
      survey_list =  <Col className="text-center font-weight-bold"><p className="text-success" style={{fontSize:"50px"}}>Loading...{' '}</p></Col>;
    }
    else {
      if (this.state.survey_data.length <= 0) {
        survey_list =  <Col className="text-center font-weight-bold"><p className="text-warning" style={{fontSize:"70px"}}><FaRegFrown /></p>No Survey Found as per your profiling.To qualify for more surveys, you may want to update <Link to="/">your profile</Link></Col>;
      }
    }
    return (
      <div className="MySurveysWrapper">
        <LoadingOverlay
            active={this.state.loading}
            spinner={<ClockLoader
                css={override}
                size={150}
                color={"#123abc"}
                
              />}
          />
        <Container fluid className="border-bottom border-top">
					<Row>
						<Sidebar />
						<Col>
							<Row>
								<Col sm={12} className="text-primary font-weight-bold mt-2 mb-2">
								My Surveys
								</Col>
							</Row>
							<Row>
                { this.state.survey_data.length > 0 ? 
                  <p className="ml-3 p-2 w-100 text-light d-block bg-primary"><FaArrowAltCircleRight /> We've found the best earning surveys to match your Current profile!!!</p>
                : <></>
                }
                
                {/* All points earned on these surveys can be redeemed for gift cards available in our rewards catalogue. */}
                
                
                {
                  survey_list
                }
                
                
              </Row>
						</Col>
					</Row>
				</Container>
			</div>
    );
  }
}

MySurveys.propTypes = {
  // bla: PropTypes.string,
};

MySurveys.defaultProps = {
  // bla: 'test',
};

export default MySurveys;
