import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class AboutUs extends Component {
  render() {
    return (
      <div className="AboutUs">
        
        <div className="jumbotron">
          <h1 className="display-9">Our Mission</h1>
          <p className="lead">
          Our mission is to create the most active community of global members which enables every member to exchange, share expertise, opinions, views and individual experiences and get paid and rewarded instantly. 
          </p>
          <hr className="my-4"></hr>
          
          
        </div>
        
      </div>
    );
  }
}

export default AboutUs;