import React from 'react';
import {API_PATH} from "../../components/App/Constants"
import API from "../api"
import PropTypes from 'prop-types';
//import { Test } from './Services.styles';
import { toast } from 'react-toastify';

const refreshToken = () => {
  let refreshToken = localStorage.getItem(API_PATH.refresh_token_name)
  API.post(API_PATH.refresh_token, {refresh:refreshToken})
    .then(res => {
      localStorage.setItem(API_PATH.token_name,res.data.access)      
    })
    .catch(err => {
      goToHome()
    });
}

const excludeHeaderAndFooter = () => {
  let pathName = window.location.pathname
  const exclude_path_names = ["/user/unsubscribe-email/"]
  return exclude_path_names.indexOf(pathName) >= 0 ? true : false
}
const goToHome = () => {
  localStorage.removeItem(API_PATH.token_name)
  localStorage.removeItem(API_PATH.refresh_token_name)
  localStorage.removeItem("_o_u")
  window.location.replace("/");
};
const goToSurvey = (surveyId) => {
  API.post(API_PATH.survey_detail + surveyId + "/")
    .then(res => {
      window.location.replace(res.data.data[0]["paas_survey_url"])
    })
    .catch(err => {
      if(err.response && err.response.status == 401) {
        goToHome()
      }
      else {
        toast.error("Please try again later!", { autoClose: 15000, position: toast.POSITION.TOP_RIGHT })
      }
    });
}

export const sharedServices = { 
  goToHome,
  goToSurvey,
  excludeHeaderAndFooter
}



