import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
//import { Test } from './Gdpr.styles';
class Gdpr extends React.Component {
  componentDidMount = () =>{
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="GdprWrapper">
        <Container fluid>
          <Row>
            <Col className="privacypolicyText">
              <ul className="" style={{listStyle:"None"}}>
                <li>
                  <p className="col-sm-12 text-right"><NavLink to="/privacy-policy">Privacy Policy</NavLink></p>
                  <p className="h2">Addendum 2 on 23rd March 2021</p>
                  <span>
                    <p>
                    OnUs Point, owned and managed by PAAS Research Pvt Ltd, is firmly committed to protect your privacy and avoid the dissemination of your personally identifiable information, in accordance with international regulations including, but not limited to the GDPR and ISO.
                    </p>
                    <p>
                    Your Controls and Choices
                    </p>
                    <ul style={{listStyle:"lower-alpha"}}>
                      <li>
                        <p className="h5">Communication Preferences</p>
                        <span>
                          <p>We may contact you via email, Newsletters, telephone or SMS. If you no longer wish to receive our newsletter and any promotional communications, you may opt-out of receiving them by following the instructions included on such communications or on the Services. We also have the option for you to unsubscribe from the email communication within your OnUs  account preference.</p>
                        </span>
                      </li>
                      <li>
                        <p className="h5">Accessing and Updating Your Personal data</p>
                        <span>
                          <p>
                          When you use the Services, we make good faith efforts to provide you with access to your personal data upon your request and either provide you the means to correct this information if it is inaccurate, or to delete such information at your request if it is not otherwise required to be retained by law or for legitimate business purposes. To do so, please contact us at <a href="mailto:privacy@onuspoint.com">privacy@OnUsPOINT.com</a> with your name and the information requested to be accessed, corrected or removed, or if you are a registered user of the Platform, sign in to your account, go to your profile, and make the desired changes. If you cease using the Platform or we terminate your access to the Platform in accordance with your Customer Agreement, you may no longer have the ability to access or update your information.
                          </p>
                          <p>
                          We may decline to process requests that are unreasonably repetitive or systematic, require disproportionate technical effort (for instance, requests concerning information residing on backup tapes), jeopardize the privacy of others, would be extremely impractical, or for which access is not otherwise required. In any case where we provide information access and correction, we perform this service free of charge, except if doing so would require a disproportionate effort. We may also retain your information as necessary to support the Services, comply with our legal obligations, for audit purposes or resolve disputes.
                          </p>
                          <p>
                          You may have additional rights regarding the access, deletion, or processing of your personal data. Please refer to the relevant sections for more information about your rights and choices under California law, or in the UK, Switzerland, and the EU/EEA.
                          </p>
                        </span>
                      </li>
                    </ul>
                  </span>
                </li>
                <li>
                  <p className="h2">Children</p>
                  <span>
                    <p>
                    The Services are not intended for use by individuals under the age of 18. We do not knowingly collect or solicit personal data from children under the age of 13. In some countries we may allow users between the age of 13-18 to register for the services, in our sole discretion. We reserve the right to refuse the collection of information from minors for any reason.
                    </p>
                    <p>
                    If you are under the age of 18, you may not attempt to register for the Services or send any information about yourself to us. In the event that we confirm that we have collected personal data from children without verification of parental consent, we will delete that information promptly. If you are a parent or legal guardian of a child and believe that we might have any information from or about such child, please contact us at <a href="mailto:privacy@onuspoint.com">privacy@OnUsPOINT.com</a>.
                    </p>
                  </span>
                </li>
                <li>
                  <p className="h2">Cross-Border Data Transfer</p>
                  <span>
                    <p>Whenever we transfer your personal data to countries not deemed by the European Commission (or other appropriate local authorities) to provide an adequate level of personal data protection, the transfer will be based on one of the following safeguards recognized as providing adequate protection for personal data, where required by applicable data protection legislation:</p>
                    <p>Contracts approved by the European Commission which impose data protection obligations on the parties to the transfer. For further details, see the European Commission Standard Contractual Clauses for the transfer of personal data to third countries.</p>
                    <p>The derogations under GDPR Article 49 (or analogous provision in local data protection law), such as where transfers are necessary to fulfil a contract or provide the services you request, or when you provide your consent.</p>
                    <p>Please contact us at <a href="mailto:privacy@onuspoint.com">privacy@OnUsPOINT.com</a> if you want further information on the specific mechanism used by us when transferring your personal data to third countries.</p>
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
Gdpr.propTypes = {
  // bla: PropTypes.string,
};

Gdpr.defaultProps = {
  // bla: 'test',
};

export default Gdpr;
