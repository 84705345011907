import React from 'react';
import earnPoints from '../../../assets/img/Earn-Points-img.jpg';
import participate from '../../../assets/img/Participate.jpg';
import recieveInvites from '../../../assets/img/Receive-Invitations-img.jpg';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './HowItWorks.scss'

function HowItWorks() {
    return (
        <div className="HowItWorks">
            <Container fluid className="middle-sec pl-0 pr-0">
                <Container>
                    <h2 className="heading-midd">H O W  I T  W O R K S</h2>
                </Container>
                <Row className="row-padd">
                    <Col sm={6} className="box no-padd">
                        <div className="box-heading">
                            <NavLink to="">Community</NavLink>
                            <p className="text-dark">OnUs community is one of the fastest growing online community for sharing experiences, opinions and expertise</p>
                        </div>
                    </Col>
                    <Col sm={6} className="midd-img no-padd">
                        <img src={earnPoints} alt="" className="img-fluid" />
                    </Col>
                
                </Row>
                <Row className="row-padd">
                    <Col sm={6} className="no-padd midd-img position-relative left">
                        <img src={participate} alt="" className="img-fluid" />
                    </Col>
                    <Col sm={6} className="no-padd box position-relative right">
                        <div className="box-heading">
                            <NavLink to="">Receive Invitations</NavLink>
                            <p className="text-dark">Join us now and receive emails for relevant activities and Surveys</p>
                        </div>
                    </Col>
                </Row>
                <Row className="row-padd">
                    <Col sm={6} className="box no-padd">
                        <div className="box-heading">
                            <NavLink to="">Rewards And Earnings</NavLink>
                            <p className="text-dark">Start earning money and rewards with the tips of your fingers</p>
                        </div>
                    </Col>
                    <Col sm={6} className="no-padd midd-img">
                        <img src={recieveInvites} alt="" className="img-fluid" />
                    </Col>
                </Row>
                    
                
            </Container>
        </div>
    );


};

export default HowItWorks;