import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
//import { Test } from './TermsAndCondition.styles';

const TermsAndCondition = (props) => (
  <div className="TermsAndConditionWrapper">
    <Container fluid>
      <Row>
        <Col className="privacypolicyText">
          <ul className="" style={{listStyle:"decimal"}}>
            <li>
              <p className="h2">
              Applicability
              </p>
              <span>
                <p>
                These Terms and Conditions apply to your use of OnUs POINTPOINT panels, survey-related services or any similar services (“Services”) including, and not limited to:
                <ul>
                  <li>Membership of OnUs POINT panel or subsidiary, including, and not limited to, the Panel.</li>
                  <li>Use & access to any Panel website.</li>
                  <li>Participation in any survey or study offered or similar services, provided, hosted, or administered by or OnUs POINT Panel.</li>
                  <li>Your eligibility for redemption of rewards and incentives offered for initiatives and activities, including, and not limited to, successfully completing surveys.</li>
                </ul>
                </p>
                <p>All references in these Terms to “Us” or “We” refer to OnUs POINT.</p>
                <p>These “Terms” or “Agreement” constitute a legally binding agreement between you and OnUs POINT. By signing up, accessing, using,  or participating in any of the Services you hereby agree to comply with, and be bound by, these Terms.</p>
                <p>OnUs POINT holds the right to refuse, restrict, prohibit, or reject your access to, use of, and participation in the Services, at any time and for any reason.</p>
                <p>Please read the following Terms of Use carefully before using any of the Services available on the website. Your use of the Service constitutes your agreement to be bound by this Agreement. </p>
                <p>THIS AGREEMENT ALSO INCLUDES A MANDATORY ARBITRATION CLAUSE AND CLASS ACTION WAIVER. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE SERVICE.</p>
              </span>
            </li>
            
            <li>
              <p className="h2">
                Details
              </p>
              <span>
              <p>
                <ul>
                  <li>You will be asked to provide a personal email address and a password to register with the panel. You must not disclose your password to anyone, and you are solely responsible for safe keeping of the password. OnUs POINT will not be liable for any unauthorized use of your account, which includes unauthorized use of your email address and password.</li>
                  <li>You will be responsible for ensuring that any and all details shared about you with OnUs POINT are up to date and are authentic.</li>
                  <li>You undertake all information you give as a panelist will be correct and accurate.</li>
                  <li>By submitting your application for membership to the panel, you consent to OnUs POINT, the collection, storage, processing and sharing of your personal information.</li>
                </ul>
              </p>
              </span>
            </li>
            <li>
              <p className="h2">Change to Terms of Use</p>
              <span>
                <p>All information on this Website is subject to change without notice and any liability. OnUs POINT holds and reserves the right to change terms & conditions within which OnUs POINT Web Site is presented, including but not limited to the commercial charges associated with the use of OnUs POINT Web Site.</p>
              </span>
            </li>
            <li>
              <p className="h2">Registration and Authentication</p>
              <span>
                <p>To register as a member, you must register with the Panel and provide some personal information. Panel members are required to authentically provide such information. OnUs POINT reserves the right to restrict or prohibit your use of, access to, and/or participation in the Services if you provide information inaccurately of falsely, or OnUs POINT reasonably suspects that you have provided information that is untrue, inaccurate, not current, or incomplete.</p>
                <p>OnUs POINT will issue a username and password to a Panel member or allow a Panel member to create his/her own username and password. Panel members are solely responsible for the security of their usernames and passwords and will be solely liable and responsible for any use, whether authorized or unauthorized, of their membership accounts.</p>
                <p>Panel membership is specific and limited to the individual who registers for membership. </p>
                <p>You acknowledge that you are accessing, using, and/or participating in the Services in the capacity of an individual, and no agency, partnership, joint venture, employee-employer or franchisor-franchisee relationship is intended or created by this agreement.</p>
              </span>
            </li>
            <li>
              <p className="h2">Confidentiality</p>
              <span>
                <p>OnUs POINT’s clients may disclose confidential and/or proprietary information and materials to you as part of surveys, and such information and materials shall remain the sole and exclusive property of its owner. This confidential information may include, but not limited to, new products, ideas, concepts, campaigns, or media trailers. By becoming a member, you agree that you will keep the contents and materials disclosed to you as part of all surveys in which you participate confidential and not disclose them to any third party or use such confidential information for any purpose other than to use for survey completion. If you breach this obligation, in addition to forfeiture of your points and termination of your membership account, you shall be liable for all damages, claims and fees to OnUs POINT and/or our client for damages caused by the result of your breach</p>
                </span>
            </li>
            <li>
              <p className="h2">Unauthorized and Prohibited Use</p>
              <span>
                <p>Your use of the OnUs POINT website is subject to your warrant to OnUs POINT that you will not use the OnUs POINT website for any purpose that is unlawful or prohibited by these terms, conditions, and notices. You shall not use the OnUs POINT website in any manner which could damage, disable, burden, or impair the OnUs POINT website or interfere with any other party's use of OnUs POINT website. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the OnUs POINT website.</p>
              </span>
            </li>
            <li>
              <p className="h2">Third Party Websites</p>
              <span>
                <p>The website may contain links to other websites not maintained by OnUs POINT, and information about products and services offered by third parties. OnUs POINT does not independently verify the business practices of such third parties and makes no warranties on their behalf. The availability of any link or reference to any third party shall in no way constitute an endorsement by OnUs POINT. You should read the terms and conditions of any such website you visit.</p>
                <p>Any software that may be downloaded from a website operated by a third party is the responsibility of that third party. OnUs POINT accepts no liability for any loss or damage caused by viruses or otherwise arising as a result of you downloading or use of such software. To avoid any ambiguity, third party website shall solely be liable for all damages, claims and fees.</p>
              </span>
            </li>
            <li>
              <p className="h2">Rewards</p>
              <span>
                <p>During the use of Services, you may have the opportunity to earn rewards, incentives, and entries into prize draws or sweepstakes. Information, official rules, and terms and conditions for rewards, incentives, and prize draws or sweepstakes may be available on the Panel Website, at the beginning or end of a survey, in survey invitations, on the website(s) for redeeming rewards, incentives, and prizes, and/or may be described in any newsletter or other communication distributed or published by OnUs POINT.</p>
                <p>Rewards, incentives, and prizes are non-transferrable and can change from time to time.</p>
              </span>
            </li>
            <li>
              <p className="h2">Policy to Unsubscribe and deactivation of membership account.</p>
              <span>
                <p>Panel members may unsubscribe or request for deactivation of membership account from the Services at any time by following any of the below:
                  <ul>
                    <li>Following unsubscribe procedure mentioned on the Website or contained in any email received from OnUs POINT.</li>
                    <li>By sending an email to the to OnUs POINT. OnUs POINT shall put reasonable effort to read and respond to each email request within a reasonable period after receipt.</li>
                  </ul>
                </p>
                <p>Upon termination or deactivation, OnUs POINT will make reasonable efforts to remove a Panel member's contact information from any further communication or contact lists. Please allow reasonable time to the complete removal of contact information from OnUs POINT’s communication or contact lists; during which period, the member may receive communications which were created or compiled prior to termination. Any such communications should be ignored for such durations. </p>
              </span>
            </li>
            <li>
              <p className="h2">Liability Disclaimer</p>
              <span>
                <p>The information, software, products, and services included in or available through OnUs POINT web site may have inaccuracies or typographical errors. OnUs POINT and/or its suppliers may make improvements and/or changes in the OnUs POINT web site periodically. Sole information received via the OnUs POINT web site should not be banked and relied upon for personal, legal or financial decisions and you should consult an appropriate professional for specific advice tailored to your situation.</p>
                <p>OnUs POINT and its service providers make no warranty regarding any goods or services purchased or obtained through the website or any transactions entered into through the website and are not responsible for any use of confidential or private information by merchants selling through the website or other users. no advice or information, whether oral or written, obtained by you from OnUs POINT, affiliates or through the site, shall create any warranty not expressly made herein.</p>
                <p>In no event shall OnUs POINT be liable for any damages (whether consequential, direct, incidental, indirect, punitive, special or otherwise) arising out of, or in any way connected with, your use or the inability to use the website or for cost of procurement of substitute goods and services or resulting from any goods or services purchased or obtained or messages received or transactions entered into through the website or resulting from unauthorized access to or alteration of your transmissions or data, including damages for loss of profits, use, data or other intangible, regardless of whether such damages are based on contract, strict liability, tort or other theories of liability, and also regardless of whether OnUs POINT was given actual or constructive notice that damages were possible.</p>
              </span>
            </li>
            <li>
              <p className="h2">Compliance to Laws</p>
              <span>
                <p>As a user of the OnUs POINT website, you acknowledge and agree that you will comply with all current and future applicable international, national, Federal, state, and/or local laws, codes, regulations, rules and/or requirements regarding your use of, participation, in, and/or access to the Services.</p>
              </span>
            </li>
            <li>
              <p className="h2">Privacy</p>
              <span>
                <p>For information on OnUs POINTs privacy information and how we use personal information collected from you, please view the <NavLink to="/privacy-policy/">Privacy Policy</NavLink>.</p>
              </span>
            </li>
            <li>
              <p className="h2">Communication</p>
              <span>
                <p>Any information, artifacts, or material as a user you send to OnUs POINT, except your personal information including questions, comments, panel responses and suggestions shall be treated as non-confidential and non-proprietary.</p>
                <p>The above is in exception to the extent that it is covered by the <NavLink to="/privacy-policy/">Privacy Policy</NavLink>.</p>
              </span>
            </li>
            <li>
              <p className="h2">Contact</p>
              <span>
                <p>If you have any questions, suggestions, or feedback regarding these website Terms and Conditions, please contact OnUs POINT by emailing <a href="mailto:contactus@onuspoint.com">contactus@onuspoint.com</a></p>
              </span>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </div>
);

TermsAndCondition.propTypes = {
  // bla: PropTypes.string,
};

TermsAndCondition.defaultProps = {
  // bla: 'test',
};

export default TermsAndCondition;
