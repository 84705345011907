import React, { Component } from "react";
import logo from "../../assets/img/logo.png";
import LoginForm from "../User/LoginForm";
// import { NavLink } from "react-router-dom";
import './Header.scss'
import {Container, Navbar, Nav  } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import SharedServices from "../../shared/Services"
export default class Header extends Component {
  state = { modalShow: false };
  constructor(props) {
    super(props);
    this.state = {
      logged_in:false
    }
  }
  handleLogout = () => {
    this.props.logged_out();
  };
  render() {
    const logged_out_nav = (
        <Nav.Link onClick={() => this.setState({ modalShow: true })}>Member's Login</Nav.Link>
    );
    const logged_in_nav = (
        <Nav.Link onClick={() => this.handleLogout()}>Logout</Nav.Link>
    );
    let modalHide = () => this.setState({ modalShow: false });
    let isExcluded = SharedServices.excludeHeaderAndFooter()
    if(isExcluded) {
      return <></>
    }
    return (
      <div className="Header">
        <Container fluid className="header-top">
            <Navbar collapseOnSelect expand="lg">
              <Navbar.Brand><NavLink to="/"><img src={logo} alt="OnUs logo" /></NavLink></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto text-uppercase col-sm-10 justify-content-end font-weight-bold">
                  <Nav.Link href="/" className="nav-link">Home</Nav.Link>
                  {this.props.logged_in ? logged_in_nav : logged_out_nav}
                </Nav>
                <Nav className="flex-row">
                  <Nav.Link className="mr-2"><i className="fa fa-facebook-official" style={{color:"#4267B2"}} aria-hidden="true"></i></Nav.Link>
                  <Nav.Link className="mr-2">
                    <i className="fa fa-twitter-square" style={{color:"#00acee"}} aria-hidden="true"></i>
                  </Nav.Link>
                  <Nav.Link className="mr-2">
                    <i className="fa fa-instagram" style={{color:"#8a3ab9"}} aria-hidden="true"></i>
                  </Nav.Link>
                  <Nav.Link className="mr-2">
                    <i className="fa fa-bell" aria-hidden="true"></i>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
        </Container>
        <LoginForm
          show={this.state.modalShow}
          onHide={modalHide}
        ></LoginForm>
      </div>
    );
  }
}
