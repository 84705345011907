/*
* @Author: pradeep kumar baranwal
* @Date:   2020-12-10 02:46:06
* @Last Modified by:   pradeep kumar baranwal
* @Last Modified time: 2020-12-22 15:53:12
*/
import React from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom'
import API from "../../../shared/api";
import {API_PATH} from "../../App/Constants"

import { css } from "@emotion/react";
import LoadingOverlay from 'react-loading-overlay'
import {ClockLoader} from "react-spinners";

export default class ForgotPassword extends React.Component {
	constructor(props) {
      super(props);
      this.state = {
          fields:{
            email:""
          },
          active: true,
          children: "",
          validated:false,
          loading:false,
          errors:{},
          errorsInvalid:{},
      };
      // this.handleChange = this.handleChange.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange = (event) => {
      let fields = this.state.fields;
      const isCheckbox = event.target.type === "checkbox";
      
      fields[event.target.name] = isCheckbox ? event.target.checked : event.target.value;
      this.setState({
        fields
      });

  };
  validate = () => {
        let error = {}
        if(!this.state.fields.email) {
          error["email"] = "This field is required.";
        }
        else {
          //email check regex
          const rxEmail = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!rxEmail.test(this.state.fields['email'])) {
            error["email"] = "Please input valid email address";
          }
        }
        this.setState({
            errorsInvalid:{},
            errors:error,
          });
        if(Object.keys(error).length > 0){
            return false;
        }
        return true
    };

  successToken = () => {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 15; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    let isValid = this.validate();
    if (form.checkValidity() === true && isValid) {
      this.setState({ loading: true });
      API.post(API_PATH.password_reset, this.state.fields)
        .then((response) => {
            let success_token = this.successToken()
            localStorage.setItem("password_reset", success_token);
            window.location.replace("/user/forgot-password/"+success_token);
            this.setState({loading:false})
          
        })
        .catch((error) => {
            let errorData = error.response.data
            this.setState({loading:false})
            this.setState({errorsInvalid:errorData})
            
        });
        return true;
    }
    this.setState({ validated: true });
  };
  successHtml = () => {
    return (
          <Row>
            <Col className="text-center"> Please check your email address to reset password</Col>
          </Row>
      )
  }
  formHtml = () => {
    return (
      <Form
        onSubmit={this.handleSubmit}
        style={{lineHeight: "1rem" }}
        noValidate 
        validated={this.state.validated}
      >
        <Form.Group as={Row}>
          <Col>
            <Form.Label htmlFor="emailInput">Enter the email address associated to your account to reset password.</Form.Label>

            <Form.Control 
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              size="sm"
              name="email"
              id="emailInput"
              type="email"
              minLength="4"
              maxLength="100"
              placeholder="Enter Email Address"
              value={this.state.fields.email}
              onChange={this.handleChange}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors.email}
            </Form.Control.Feedback>
            <div className="text-danger" style={{fontSize : "80%"}}>
              {this.state.errorsInvalid.email}
            </div>
          </Col>
        </Form.Group>
        <Row className="pt-2">
            <Col sm={6} className="text-left">
                <Button type="submit" className="btn btn-primary m-auto">Submit</Button>
            </Col>
            <Col className="text-md-right pt-1">
                Do not Have an Account? <NavLink to="/registration/">Join Now</NavLink>
            </Col>
        </Row>
      </Form>
    )
  };
  
  render() {
    const { success }  = this.props.match.params;
    let content = this.formHtml();
    if(success === localStorage.getItem("password_reset")) {
      content = this.successHtml()
    } 
    return (
	  	<div className="ForgotPassword">
        <LoadingOverlay
          active={this.state.loading}
          spinner={<ClockLoader
              size={150}
              color={"#123abc"}
              
            />}
        >
        </LoadingOverlay>
		    <Container fluid >
        	<Container className="d-block pt-5 pb-5">
          	<Row>
              <Col sm={8}  className="m-auto">
                <Card className="shadow">
                  <Card.Header className="text-center text-light font-weight-bolder bg-primary">Forgot Password</Card.Header>
                  <Card.Body>
                    {content}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
        	</Container>
        </Container>
		</div>
	  )
    
  }  
} 