import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { AppContainer } from 'react-hot-loader';
import './index.scss';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <AppContainer>
      <App />
    </AppContainer>,
  document.getElementById('root')
);

// const cors = require('cors');
serviceWorker.unregister();
