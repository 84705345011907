import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
//import { Test } from './AccountHistory.styles';
import Sidebar from '../Sidebar'
import { Container, Row, Col } from 'react-bootstrap';
import {API_PATH} from "../../App/Constants"
import API from "../../../shared/api";
import SharedServices from "../../../shared/Services"

class AccountHistory extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      accountHistory: [],
    };
  }

  componentDidMount = () => {
    API.get(API_PATH.account_history, {})
    .then(res => {
      this.setState({
        accountHistory : res.data.data
      })
    })
    .catch(err => {
      if(err.response.status == 401) {
        SharedServices.goToHome()
      }
      
    });
  };
  componentWillUnmount() {
      // fix Warning: Can't perform a React state update on an unmounted component
      this.setState = (state,callback)=>{
          return;
      };
  }

  render () {
    return (
      <div className="AccountHistoryWrapper">
        <Container fluid className="border-bottom border-top">
					<Row>
						<Sidebar />
						<Col>							
							<Row>
                <Col className="text-primary font-weight-bold mt-2 mb-2">
                  Account History
                </Col>
              </Row>
              <Row>
                <Col className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Survey</th>
                        <th>Points Description</th>
                        <th>Transaction Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.accountHistory.map((history,index)=>
                          <tr key={index}>
                            <td>{history.start_time}</td>
                            <td>{history.survey_id}</td>
                            <td>{history.payout_in_point}</td>
                            <td>{history.click_status}</td>
                          </tr>
                        )
                      }
                      
                    </tbody>
                  </table>
                </Col>
              </Row>
						</Col>
					</Row>
				</Container>
      </div>
    );
  }
}

AccountHistory.propTypes = {
  // bla: PropTypes.string,
};

AccountHistory.defaultProps = {
  // bla: 'test',
};

export default AccountHistory;
