import React from 'react';
// import CustomRoute from "./CustomRoute";
import './main.css'
import './App.scss';
import Home from '../Home'
import Header from '../Header'
import Footer from '../Footer'
// import '/css/main.css'
import Joinus from '../User/JoinUs/JoinUs'
import UnsubscribeEmail from '../User/UnsubscribeEmail'
import ForgotPassword from '../User/ForgotPassword/ForgotPassword'
import ResetPassword from '../User/ResetPassword/ResetPassword'
import AccountActivation from '../User/AccountActivation/AccountActivation'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import AboutUs from '../AboutUs';
import PrivacyPolicy from '../PrivacyPolicy';
import Gdpr from '../PrivacyPolicy/Gdpr';
import Ccpa from '../PrivacyPolicy/Ccpa';
import EndSurvey from '../EndSurvey';
import NotFound from '../NotFound';
import ContactUS from '../ContactUs/ContactUS';
import ThanksForJoining from '../User/JoinUs/ThanksForJoining/ThanksForJoining';
import TermsAndCondition from '../TermsAndCondition';
import {API_PATH} from "./Constants"
import API from "../../shared/api";
import SharedServices from "../../shared/Services";
import TransactionHistory from '../Restricted/AccountHistory'
import UserAccount from '../Restricted/MyAccount'
import UserProfile from '../Restricted/Profile'
import UserRewards from '../Restricted/Rewards'
import MySurveys from '../Restricted/MySurveys'
import UserQuery from '../Restricted/UserQuery'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ClientJS from "clientjs";

// import LogRocket from 'logrocket';
// LogRocket.init('nmwwi3/my-test-project');
// import Loader from "../../shared/Loader"
// <Route path="/signup/" component={Signup}/>
// <Route path="/signup/:params" component={Signup}/>
// <Route path="/run_away/:params" component={Runaway}/>
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      logged_in : localStorage.getItem(API_PATH.token_name) ? true : false,
      user:{}
    }
  }

  goToHome = () => {
    SharedServices.goToHome()
  }

  componentDidMount(){
    // Create a new ClientJS object
    var client = new window.ClientJS();
    
    // Get the client's fingerprint id
    var fingerprint = client.getFingerprint();
    
    // if ("geolocation" in navigator) {
    //   navigator.geolocation.getCurrentPosition(function(position) {
    //     console.log(position)
    //     console.log("Latitude is :", position.coords.latitude);
    //     console.log("Longitude is :", position.coords.longitude);
    //   },function(error){
    //     console.error("Error Code = " + error.code + " - " + error.message);
    //   });
    // }

    if(this.state.logged_in){
      API.get(API_PATH.current_user, {})
      .then(res => {
        this.setState({user:res.data})
        localStorage.setItem("_o_u",JSON.stringify(res.data))
      })
      .catch(err => {
        this.goToHome();
      });
    }
  }

  logout = (event) =>{
    this.goToHome();
  }
  
  render() {
    let pathName = window.location.pathname
    return (
      <div className="App">
        <BrowserRouter>
          <Header 
            logged_in={this.state.logged_in}
            logged_out={this.logout}
          />
          <ToastContainer autoClose={3000} />
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/registration/thanks-for-joining-us/" component={ThanksForJoining}/>
            <Route path="/end-survey/" component={EndSurvey}/>
            <Route path="/registration/" component={Joinus}/>
            <Route path="/user/activate-account/" component={AccountActivation}/>
            <Route path="/user/forgot-password/:success?" component={ForgotPassword}/>
            <Route path="/user/reset-password/:key" component={ResetPassword}/>
            <Route path="/user/unsubscribe-email/" component={UnsubscribeEmail}/>
            <Route path="/terms-and-condition/" component={TermsAndCondition}/>
            <Route path="/our-mission/" component={AboutUs}/>
            <Route path="/contactus/" component={ContactUS}/>
            <Route path="/privacy-policy/" component={PrivacyPolicy}/>
            <Route path="/privacy-policy-gdpr/" component={Gdpr}/>
            <Route path="/privacy-policy-ccpa/" component={Ccpa}/>
            <Route path="/my-account/" component={UserAccount}/>
            <Route path="/transaction-history/" component={TransactionHistory}/>
            <Route path="/user-query/" component={UserQuery}/>
            <Route path="/my-profile/:category" component={UserProfile}/>
            <Route path="/rewards/" component={UserRewards}/>
            <Route path="/my-surveys/" component={MySurveys}/>
            <Route component={NotFound} />
          </Switch>
          {/* {if(pathName){}} */}
          
          
          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
