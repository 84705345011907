import React, { PureComponent } from 'react';
import { Container, Row, Col, Card, Button, Form, Alert } from 'react-bootstrap';
import Sidebar from '../Sidebar'
import PropTypes from 'prop-types';
import {API_PATH} from "../../App/Constants"
import API from "../../../shared/api"
import { ToastContainer, toast } from 'react-toastify';
//import { Test } from './UserQuery.styles';

class UserQuery extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      validated:false,
      fields:{query_type:"gen"},
      success_message:<></>,
      queryType:{
        gen:"General",
        feedback:"Feedback",
        rew_rel:"Rewards Related",
        sur_rel:"Survey Related",
        tech_issue:"Technical Issue",
        // comps:"Compensation",
      },
    };
  }

  componentDidMount = () => {
    // console.log('UserQuery mounted');
  }
  handleChange = (event) => {
    let fields = this.state.fields;
    event.target.value = event.target.value.trimLeft();
    fields[event.target.name] = event.target.value;
    this.setState({
      fields
    });
  };

  handleSubmit = (ev) => {
    ev.preventDefault()
    const form = ev.currentTarget;
    let post_data = Object.assign({}, this.state.fields);
    if (form.checkValidity() === true) {
      document.getElementById("user-query-form").reset();
      API.post(API_PATH.user_query, post_data)
      .then(res => {
        toast.success("Query submitted successfully", { autoClose: 15000, position: toast.POSITION.TOP_RIGHT });
        this.setState({success_message:<Alert variant="success">Thanks for your query we will get back to you within 24-48 hours{' '}</Alert>})
      })
      .catch(err => {
        if(err.response.status == 401) {
          SharedServices.goToHome()
        }
        toast.error("Please try again!!", { autoClose: 10000, position: toast.POSITION.TOP_RIGHT });
      });
    }
    else {
      this.setState({ validated: true });
    }
    
  };
  render () {
    return (
      <div className="UserQueryWrapper">
        <Container fluid className="border-bottom border-top">
          <Row>
						<Sidebar />
						<Col>							
              <Row>
								<Col sm={12} className="text-primary font-weight-bold mt-2 mb-2">
								Contact Us
								</Col>
							</Row>
              <Row className="mt-3 align-middle" style={{minHeight:"300px"}}>
                <Col>
                {this.state.success_message}
                  <Form
                    onSubmit={event=>this.handleSubmit(event,"account")}
                    noValidate
                    id="user-query-form"
                    validated={this.state.validated}
                  >
                    <Form.Row>
                      <Form.Group as={Col} sm={6} controlId="formSubject">
                        <Form.Label>Query Type</Form.Label>
                        <Form.Control as="select" name="query_type" required defaultValue={this.state.query_type} onChange={this.handleChange}>
                          {
                            Object.keys(this.state.queryType).map((type,index)=><option key={index} value={type}>{this.state.queryType[type]}</option>)
                          }
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={6} controlId="formSubject">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control 
                          maxLength="150" 
                          name="subject" 
                          required 
                          type="text" 
                          placeholder="Enter Subject Here"
                          defaultValue={this.state.subject} 
                          onChange={this.handleChange} />
                          
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={6} controlId="formMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                          required
                          maxLength="450"
                          as="textarea"
                          name="message" 
                          defaultValue={this.state.message}
                          placeholder="Enter Message Here" 
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </Col>
              </Row>
						</Col>
					</Row>
        </Container>
      </div>
    );
  }
}

UserQuery.propTypes = {
  // bla: PropTypes.string,
};

UserQuery.defaultProps = {
  // bla: 'test',
};

export default UserQuery;
