import React from 'react'
import banner2 from '../../../assets/img/Banner.png';
import banner from '../../../assets/img/Banner-2.jpg';
import banner3 from '../../../assets/img/Banner-3.jpg';
import bntxtimg from '../../../assets/img/bn-txt-img.png';
import Carousel from 'react-bootstrap/Carousel'
import './Slider.scss'
import { NavLink } from 'react-router-dom';
export default function Slider() {
    const banner_dict = [
        {
            main_image:{name:banner,alt:"Hands Together"},
            banner_text_img:{name:bntxtimg,alt:"The art of making easy money instantly"},
            step_title:"STEP 1",
            step_title_alt:"Join With Us",
        },
        {
            main_image:{name:banner2,alt:"Yours Opinion"},
            banner_text_img:{name:bntxtimg,alt:"The art of making easy money instantly"},
            step_title:"STEP 2",
            step_title_alt:<>Provide Your Opinion <br />in minutes</>,
        },
        {
            main_image:{name:banner3,alt:"Hands Together"},
            banner_text_img:{name:bntxtimg,alt:"The art of making easy money instantly"},
            step_title:"STEP 3",
            step_title_alt:<>Full Your Pocket <br />With Rewards</>,
        }
    ]
    return (
        
            <Carousel interval={3000} indicators={false} >
                {banner_dict.map((banner,index) => <Carousel.Item key={index}>
                    <img
                        src={banner.main_image.name}
                        alt={banner.main_image.alt}
                    />
                    <Carousel.Caption>
                        <div className="row">
                            <div className="col-md-5 col-sm-5 col-xs-5 bn-fnt-sec text-lg-center text-md-center text-sm-left text-xs-left">
                                <img src={banner.banner_text_img.name} alt={banner.banner_text_img.alt} />
                                <div className="singup">
                                <NavLink to="/registration/" className="text-white text-decoration-none"> SIGN UP NOW</NavLink>
                                
                                </div>
                            </div>
                            <div className="col-md-3 small-width">
                                <div className="bn-small-text text-left">
                                <h1>{banner.step_title}</h1>
                                <p className="text-capitalize">{banner.step_title_alt}</p>
                                </div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>)}
            </Carousel>
        
    )
}