let api_domain;

if(window.location.hostname === 'localhost'){
    api_domain= 'http://127.0.0.1:8000/';
}else{
    api_domain= 'http://api.onuspoint.com/';
}
const API_BASE_URL = api_domain;
const API_PATH = {
	current_user:"api/accounts/current_user/",
	delete_user_account:"api/accounts/delete_user/",
	update_user_preference:"api/accounts/update_preferences/",
	unsubscribe_email:"api/accounts/unsubscribe_email/",
	reason:"api/accounts/reason/",
	refresh_token:"api/accounts/refresh-token/",
	login:"api/login/",
	sent_verification_email:'api/accounts/sent-verification-email/',
	user_detail:"api/accounts/user_detail/",
	update_user_info:"api/accounts/update_user_info/",
	change_password:"api/accounts/change_password/",
	redeemption_history:"api/survey/redeemption_history/",
	account_history:"api/survey/user_transaction/",
	user_query:"api/userquery/ticket/",
	profiling_category:"api/question-category/",
	end_survey:"api/survey/trackit/",
	country:"api/country/",
	language:"api/language/",
	profiling_question:"api/profiling/profiling_question/",
	survey:"api/survey-list/",
	survey_detail:"api/survey-detail/",
	register_user:"api/user/",
	password_reset:"api/accounts/password_reset/",
	activate_account:"api/accounts/activate/",
	validate_token:'api/accounts/password_reset/validate_token/',
	confirm_token:'api/accounts/password_reset/confirm/',
	auth_prefix:"ONUS",
	token_name:"token",
	refresh_token_name:"refresh",
}
export {
	API_BASE_URL,
	API_PATH
}