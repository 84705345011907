/*
* @Author: pradeep kumar baranwal
* @Date:   2020-12-21 12:24:03
* @Last Modified by:   pradeep kumar baranwal
* @Last Modified time: 2020-12-22 02:02:14
*/
import React from 'react'
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import Sidebar from '../Sidebar'
import MyWallet from '../MyWallet'
import VerifyEmail from '../VerifyEmail'
import RandomProfiling from '../RandomProfiling'
import HotSurveys from '../HotSurveys'


class Dashboard extends React.Component {
	constructor(props) {
		super(props);	
		this.state = {
			// logged_in : localStorage.getItem(API_PATH.token_name) ? true : false,
			logged_in_user : JSON.parse(localStorage.getItem("_o_u")),
		};
	  }
	
	componentDidMount = () => {
		// this.setState({
		// 	logged_in_user : JSON.parse(localStorage.getItem("_o_u"))
		// })
	}
	
	render() {
		

		return (
			<div className="Dashboard">
				<Container fluid className="border-bottom border-top">
					<Row>
						<Sidebar />
						<Col>
							<Row>
								<Col sm={12} className="text-primary font-weight-bold mt-2 mb-2">
								Dashboard
								</Col>
							</Row>
							<VerifyEmail />
							<RandomProfiling />
							<Row className="mt-3 align-middle" style={{minHeight:"300px"}}>
								<Col sm={6} className="border rounded ml-3 mb-3">
									<p className="font-weight-bold" style={{color:"#0fbf0f",fontSize:"18px"}}>Survey Available</p>
									<HotSurveys />
								</Col>
								<Col className="border rounded ml-3 mr-3 mb-3">
									<MyWallet />
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

export default Dashboard;