/*
* @Author: pradeep kumar baranwal
* @Date:   2020-12-09 02:46:55
* @Last Modified by:   pradeep kumar baranwal
* @Last Modified time: 2020-12-19 01:47:53
*/
import axios from 'axios';
import { API_BASE_URL, API_PATH } from "../components/App/Constants";
import Cookies from 'js-cookie'


export default axios.create({
  baseURL: API_BASE_URL,
  headers: {
        Accept: 'application/json',
        "X-CSRFToken":Cookies.get('csrftoken'),
        "X-Requested-With":"XMLHttpRequest",
        Authorization:` ${API_PATH.auth_prefix} ${localStorage.getItem(API_PATH.token_name)}`
       },
});