import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
//import { Test } from './DeleteAccount.styles';

import { FaRegFrown } from "react-icons/fa";
import { AiOutlineFrown } from "react-icons/ai";
import { Form, Modal, Button, Row, Col, Alert } from 'react-bootstrap'
import API from "../../../shared/api";
import SharedServices from "../../../shared/Services";
import { ToastContainer, toast } from 'react-toastify';

import {API_PATH} from "../../App/Constants"

class DeleteAccount extends PureComponent { 
  constructor(props) {
    console.log("Delete account")
    super(props);
    this.state = {
        showModel:false,
        showAlert:false,
        fields:{deleteReason:0},
        delete_reason:[]
    };
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange = event => {
      let new_fields = { ...this.state.fields, [event.target.name]: event.target.value.trimLeft()}
      this.setState({fields:new_fields})
  };

  handleSubmit = event => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === true) {
          // go to next step
          API.delete(API_PATH.delete_user_account, {data: this.state.fields})
          .then((response) => {
              let data = response.data
              toast.success(data.message, {position: toast.POSITION.TOP_RIGHT });
              SharedServices.goToHome()
            
          })
          .catch((error) => {
              let errorData = error.response.data
              toast.error(errorData.errors, {position: toast.POSITION.TOP_RIGHT });
              console.log(errorData)
          });
      }
  };
  componentDidMount = () => {
    API.get(API_PATH.reason + "?type=1", {})
      .then(res => {
        this.setState({
          delete_reason : res.data.data,
          
        })
      })
      .catch(err => {
        if(err.response.status == 401) {
          SharedServices.goToHome()
        }
      });
  }

  componentWillUnmount = () => {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }
  setShow = (show) => {
      this.setState({showAlert:show});
      
  };

  resetDeleteForm = () => {
    this.setState({fields:{deleteReason:0}});
    this.props.onHide();
  }
  render () {
    let reset = () => {
        this.setState({showModel:false});
    };
    let alertBox = ''
    if(this.state.showAlert) {
        alertBox = (
            <Alert variant="danger" onClose={() => this.setShow(false)} dismissible>
              {this.state.errors}
            </Alert>
        )
    }
    let OtherDeleteComment = <></>
    if(this.state.delete_reason.findIndex(item => item.text.toLowerCase() === "others" && item.id == this.state.fields.deleteReason) != -1){
      OtherDeleteComment = <Form.Group controlId="otherDeleteReason">
        <Form.Label>Comment</Form.Label>
        <Form.Control 
            as="textarea"
            name="otherDeleteReason" 
            onChange={this.handleChange} 
            required 
        >
        </Form.Control>
      </Form.Group>
    }
    return (
      <div className="DeleteAccountWrapper">
        <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            onHide={reset}
        >
          <div className="modal-header">
              <div className="modal-title h3" id="contained-modal-title-vcenter">Delete Account</div>
              <button type="button" className="close"  onClick={this.props.onHide}>
                  <span aria-hidden="true">×</span><span className="sr-only">Close</span>
              </button>
          </div>
          
          <Modal.Body>
            {alertBox}
            <div className="row p-lg-3 p-md-1">
              <div className="col-lg-9  col-md-9 position-relative left">
                <h2 className="font-weight-bold">We are sad to see you go!</h2>
                <div className="font-weight-normal pb-3" style={{fontSize:"14px"}}>
                  Once you delete your account, you will no longer be able to access the portal.
                  {/* We will keep your data for next 30 days and then it will be clear from the system. */}
                </div>
                <Form onSubmit={this.handleSubmit} id="onus_login_pop_up_form">
                  <Form.Group controlId="deleteReason">
                    <Form.Label>Select Your Reason</Form.Label>
                    <Form.Control 
                        as="select"
                        name="deleteReason" 
                        onChange={this.handleChange} 
                        required 
                    >
                      <option value="">Select Reason</option>
                      {this.state.delete_reason.map((reason,i)=><option value={reason.id} key={i}>{reason.text}</option>)}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select reason.
                    </Form.Control.Feedback>
                  </Form.Group>
                  {OtherDeleteComment}
                  <Button type="submit" className="btn btn-primary mr-2">Submit</Button>
                  <Button type="reset" className="btn btn-light" onClick={()=>this.resetDeleteForm()}>Cancel</Button>
                </Form>
              </div>
              <div className="col-lg-3 col-md-3 text-warning text-center position-relative right" style={{fontSize:"140px",lineHeight:"1"}}>
                <AiOutlineFrown />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

DeleteAccount.propTypes = {
  // bla: PropTypes.string,
};

DeleteAccount.defaultProps = {
  // bla: 'test',
};

export default DeleteAccount;
