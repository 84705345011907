import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Container, Row } from 'react-bootstrap';
import {API_PATH} from "../App/Constants"
import API from "../../shared/api";
//import { Test } from './EndSurvey.styles';

import queryString from "query-string";
import NotFound from '../NotFound';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class EndSurvey extends PureComponent { 
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      search : queryString.parse(this.props.location.search),
      acceptedStatus:{
        1:1, // Terminate
        2:2, // Completed
        3:3, // Qual Terminate
        4:-1, // Quotafull
        5:-2, // Security Terminate
      },
    };
  };

  getEndPageStatus = () => {
    return this.state.acceptedStatus[Number(this.state.search.rs)];
  };

  componentDidMount = () => {
    if(this.state.hasError) {
      return false;
    }
    API.post(API_PATH.end_survey, {st:this.getEndPageStatus(),rid:this.state.search.rid,qs:this.props.location.search})
    .then(res => {})
    .catch(err => {
      this.setState({hasError:true});
    });
  };
  getMessageHtml = () => {
    let msg = '';
    switch(this.getEndPageStatus()) {
      case -1:
        msg = <>Sorry! This survey is closed for you.<br /><br /> We have reached the maximum limit on the number of participants that match your profile, that we require for this survey. We appreciate your participation and look forward to seeing you in future surveys!</>
        break;
      case 2:
        msg = <>Thank you for sharing your Valued Opinion!<br /><br />Your answers have been received and this survey is now completed.</>
        break;
      case 1:
      case 3:
      case -2:
          msg = <>Thanks a lot.<br /><br />Unfortunately, you do not qualify for this particular survey. 
          We appreciate your participation and look forward to seeing you in future surveys!</>
          break;
      default:
        msg = ""
        // <NotFound />
    }
    return <Card className="mt-3 mb-2 w-100">
        <Card.Header className="bg-primary text-light">
          Thank you for participating in this survey!!
        </Card.Header>
        <Card.Body className="font-weight-normal mt-3">
        {msg}
        </Card.Body>
        <Card.Footer><Link to="/">Got to Home</Link></Card.Footer>
      </Card>
  };
  render () {
    const status = this.getEndPageStatus()
    if(!status || !this.state.search.rid) {
      this.state.hasError = true;
    }
    let message = this.getMessageHtml()
    if (this.state.hasError) {
      // message = <NotFound />
    }
    return (
      <div className="EndSurveyWrapper">
        <Container fluid className="border-bottom border-top">
          <Row className="">
            <Col sm={12} className="text-center m-auto d-inline-flex" sm={6} style={{minHeight:"50vh"}}>
              {message}
            
            </Col>
            
          </Row>
          
        </Container>
      </div>
    );
  }
}

EndSurvey.propTypes = {
  // bla: PropTypes.string,
};

EndSurvey.defaultProps = {
  // bla: 'test',
};

export default EndSurvey;
