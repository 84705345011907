import React from 'react'
import { ProSidebar, SidebarHeader,SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { FaGifts, FaGitter, FaUserTie, FaUsersCog, FaRegIdCard, FaHistory, FaUserCog, FaAngleLeft, FaAngleRight,FaFile, FaHome } from "react-icons/fa";
import {Link, Redirect} from 'react-router-dom';
import { Col } from 'react-bootstrap';
import {API_PATH} from "../App/Constants"
import API from "../../shared/api";

import './Sidebar.scss'

export default class Sidebar extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            logged_in : localStorage.getItem(API_PATH.token_name) ? true : false,
            collapsed:false,
            colSize:2,
            showProfile:false,
            profileCategories:[]
        };
      }
    
    componentDidMount = () => {
        // API.get(API_PATH.profiling_category, {})
        // .then(res => {
        //     // console.log(res.data.data)
        //     this.setState({
        //         profileCategories : res.data.data.category
        //     })        
        // })
        // .catch(err => {
        //     if(err.response.status == 401) {
        //         SharedServices.goToHome()
        //     }
        // });
    }

    activeMenu = (nav_path) =>{
        // console.log(path);
        let currentPath = window.location.pathname;
        return currentPath == nav_path;
    }
    toggleProfile = () => {
        this.setState({showProfile:!this.state.showProfile});
    };
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }
    render() {
        // console.log(window.location.pathname)
        if(this.state.logged_in === false){
            return <Redirect to="/"/>
        }
        // if(this.state.collapsed) {
        //     this.setState({colSize:0})
        // }
        // else{
        //     this.setState({colSize:2})
        // }
        let collapse_html = <span className="pull-right" onClick={() => this.setState({ collapsed: false,colSize:2 })}><FaAngleRight /></span>
        if(!this.state.collapsed) {
            collapse_html = <span className="float-right mr-2" onClick={() => this.setState({ collapsed: true,colSize:0 })}><FaAngleLeft /></span>
        }
        return (
            <Col sm={this.state.colSize} className="pl-0 pr-0" style={{minHeight:"470px"}}>
                <ProSidebar collapsed={this.state.collapsed}>
                    <SidebarHeader className="text-center p-2">
                        Menu {collapse_html}
                    </SidebarHeader>
                    <SidebarContent className="">
                        <Menu iconShape="square">
                            <MenuItem active={this.activeMenu("/")} icon={<FaHome title="Home" />}>Dasboard <Link to="/" /></MenuItem>
                            <MenuItem active={this.activeMenu("/transaction-history")} icon={<FaHistory />}>Transaction History <Link to="/transaction-history" /></MenuItem>
                            <MenuItem active={this.activeMenu("/my-surveys")} icon={<FaFile />}>My Survey <Link to="/my-surveys" /></MenuItem>
                            
                            <MenuItem active={this.activeMenu("/rewards")} icon={<FaGifts />}>My Rewards <Link to="/rewards" /></MenuItem>
                            {
                            //     this.state.profileCategories.length > 0 ? <SubMenu title="My Profile" open={this.state.showProfile} onOpenChange={this.toggleProfile} icon={<FaUserTie />}>
                            //     {this.state.profileCategories.map((cat,i)=><MenuItem active={this.activeMenu(`/my-profile/${cat.id}`)} icon={<FaUserCog />}  key={i}>{cat.description} <Link to={`/my-profile/${cat.id}`} /></MenuItem>)}
                            // </SubMenu>:<></>
                            }
                            
                            <MenuItem active={this.activeMenu("/my-account")} icon={<FaUsersCog />}>My Account <Link to="/my-account" /></MenuItem>
                            <MenuItem active={this.activeMenu("/user-query")} icon={<FaRegIdCard />}>Contact Us <Link to="/user-query" /></MenuItem>
                        </Menu>
                    </SidebarContent>
                </ProSidebar>
            </Col>
        );
    }
}