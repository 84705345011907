import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import {API_PATH} from "../../App/Constants"
import API from "../../../shared/api";
//import { Test } from './RandomProfiling.styles';
import { ToastContainer, toast } from 'react-toastify';

class RandomProfiling extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      fields:{},
      show_profiling:true,
      errors:{},
      disbleButton:"1",
      profilingQuestions: [],
    };
  }
  componentDidMount = () => {
    API.get(API_PATH.profiling_question, {})
    .then(res => {
      this.setState({
        profilingQuestions : res.data.data.questions
      })
      
    })
    .catch(err => {
      if(err.status == 401) {
        SharedServices.goToHome()
      }
    });
  }

  handleChange = (event) => {
    let fields = [];
    let value;
    const isCheckbox = event.target.type === "checkbox";
    if(isCheckbox) {
      const checkedArr = [];
      const checkeds = document.getElementsByName(event.target.name);
      
      for (let i = 0; i < checkeds.length; i++) {
        if (checkeds[i].checked) {
          checkedArr.push(checkeds[i].value);
        }
      }
      value = checkedArr;
    }
    else {
      value = [event.target.value];
    }
    // fields[event.target.name] = value
    let prof_ques = this.state.profilingQuestions[0]
    value.map((ans)=>
      fields.push({question_id:prof_ques["id"],question_title:prof_ques["short_title"],answer_code:ans.split("-")[0],
                  question_text:prof_ques["title"],answer_text:ans.split("-")[1]})
    )
    this.setState({
      fields
    });
    if(value.length>0) {
      this.setState({disbleButton:""});
    }
    else{
      this.setState({disbleButton:"1"});
    }
  };
  handleSubmit = ev => {
    ev.preventDefault();
    API.post(API_PATH.profiling_question, this.state.fields)
    .then(res => {
      toast.success("Profile updated successfully", { autoClose: 15000, position: toast.POSITION.TOP_RIGHT });
      this.setState({show_profiling:false});
    })
    .catch(err => {
      if(err.response.status == 401) {
        SharedServices.goToHome()
      }
      toast.error("Please try again!!", { autoClose: 15000, position: toast.POSITION.TOP_RIGHT });
    });

  };
  typeName = (type) =>{
    switch(type) {
      case "single_punch":
        return "radio";
        break;
      case "multi_punch":
        return "checkbox";
        break;
    }
  };
  componentWillUnmount() {
      // fix Warning: Can't perform a React state update on an unmounted component
      this.setState = (state,callback)=>{
          return;
      };
  };
  render () {
    if (!this.state.show_profiling){
			return (<></>)
    }
    else if (this.state.profilingQuestions.length <= 0) {
      return (<></>)
    }
    
    return (
      <div className="RandomProfilingWrapper">
        <Row>
          <Col className="border rounded ml-3 mr-3">
            <p className="font-weight-bold" style={{color:"#0fbf0f",fontSize:"18px"}}>Answer a quick question to help us provide you with more survey opportunities!</p>
            <Form noValidate onSubmit={this.handleSubmit}>
              {this.state.profilingQuestions.map((que,i)=>
                <Form.Group as={Col} key={i}>
                  {/* <p>
                    <strong>{que.category}</strong>
                  </p> */}
                  <Form.Label className="font-weight-bold">{que.title}</Form.Label>
                  <Row>
                  {que.options.map((opt,ind)=><Form.Check className="col-md-4" key={ind}
                    type={this.typeName(que.input_type)}
                    required
                    name={que.id}
                    value={`${opt.answer_code}-${opt.choice}`}
                    onChange={this.handleChange}
                    label={opt.choice}
                    id={`id-${opt.answer_code}`}
                  />)}
                  </Row>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Col sm={{ span: 10, offset: 2 }}>
                  <Button type="submit" disabled={this.state.disbleButton}>Submit</Button>
                </Col>
              </Form.Group>
            </Form>
            
          </Col>
        </Row>
      </div>
    );
  }
}

RandomProfiling.propTypes = {
  // bla: PropTypes.string,
};

RandomProfiling.defaultProps = {
  // bla: 'test',
};

export default RandomProfiling;
