import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
//import { Test } from './Rewards.styles';
import { Container, Row, Col, Card, Button, Form,Table } from 'react-bootstrap';
import Sidebar from '../Sidebar'
import {Link} from 'react-router-dom'
import API from "../../../shared/api";
import {API_PATH} from "../../App/Constants"
import SharedServices from "../../../shared/Services"

class Rewards extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      minPointToCash: 500,
      totalPoints:{},
      summary:[]
    };
  }

  componentDidMount = () => {
    API.get(API_PATH.redeemption_history, {})
    .then(res => {
      let data = res.data.data
      this.setState({
        summary : data.transactions,
        minPointToCash:data.minPointToCash,
        totalPoints:data.totalPoints
      })
    })
    .catch(err => {
      if(err.response.status == 401) {
        SharedServices.goToHome()
      }
      
    });
  }

  totalPointsEarned = () => {
    // Object.keys((this.state.totalPoints))
    let sum = 0;
    for (var key in this.state.totalPoints) {
        sum += this.state.totalPoints[key];
    }
    return sum;
  };

  requiredMinPoint = () => {
    const minPoints =  this.state.minPointToCash - this.totalPointsEarned()
    if(minPoints >= 0) {
      return <>You only need <b>{minPoints}</b> more points to redeem</>
    }
    else {
      return <>Yay!! You can cahout the points</>
    }
  };
  componentWillUnmount() {
      // fix Warning: Can't perform a React state update on an unmounted component
      this.setState = (state,callback)=>{
          return;
      };
  }
  render () {
    return (
      <div className="RewardsWrapper">
        <Container fluid className="border-bottom border-top">
          <Row>
            <Sidebar />
            <Col>
              <Row>
                <Col sm={12} className="text-primary font-weight-bold mt-2 mb-2">
                  Rewards
                </Col>
              </Row>
              <Row>
                <Col className="">
                  <div className="jumbotron jumbo-top rounded text-center ">
                    <p>You have earned <b>{this.totalPointsEarned()}</b> points! {this.requiredMinPoint()}</p>

                    <p>Find more surveys and earn more points. To qualify for more surveys, you may want to <Link to="/">update your profile</Link> </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="">
                  <div className="jumbotron jumbo-top rounded text-center ">
                    <h3 className="text-secondary pt-1">{this.totalPointsEarned()}</h3>
                    <h4 className="text-success">Total Points Earned</h4>
                  </div>
                </Col>
                <Col className="">
                  <div className="jumbotron jumbo-top rounded text-center ">
                    <h3 className="text-secondary pt-1">
                      {/* {this.state.totalPoints.survey} */}
                      0
                      </h3>
                    <h4 className="text-success">Total Points Redeemed</h4>
                  </div>
                </Col>
                {/* <Col md={3} className="">
                  <div className="jumbotron jumbo-top rounded text-center ">
                    <h3 className="text-secondary pt-1">0</h3>
                    <h4 className="text-success">Earned Via Referrals</h4>
                  </div>
                </Col>
                <Col md={3} className="">
                  <div className="jumbotron jumbo-top rounded text-center ">
                    <h3 className="text-secondary pt-1">0</h3>
                    <h4 className="text-success">Earned Via Polls</h4>
                  </div>
                </Col> */}
              </Row>
                <Row className="">
                  <Col className="mb-2">                    
                    <Card>
                      <Card.Header className="font-weight-bold" style={{color:"#0fbf0f",fontSize:"18px"}}>Reward Summary</Card.Header>
                      <Card.Body className="p-0">
                        <Table responsive striped hover size="md">
                          <thead>
                            <tr>
                              <th>S. No.</th>
                              <th>Date</th>
                              <th>Points</th>
                              <th>Reward Type</th>
                              <th>Reward Status</th>
                              {/* <th>Description</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.summary.length > 0 ?
                              this.state.summary.map((trans,index)=>
                              <tr key={index}>
                                <td>{index+1}</td>
                                <td>{trans.start_time}</td>
                                <td>{trans.payout_in_point}</td>
                                <td>{trans.click_type}</td>
                                <td>{trans.click_status}</td>
                                {/* <td>{trans.description}</td> */}
                              </tr>  
                              ) : <tr><td colSpan="5" className="text-center">No Summary Found!!</td></tr>
                            }
                            
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                    
                  </Col>
                </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Rewards.propTypes = {
  // bla: PropTypes.string,
};

Rewards.defaultProps = {
  // bla: 'test',
};

export default Rewards;
