import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//import { Test } from './Ccpa.styles';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
class Ccpa extends React.Component {
  componentDidMount = () =>{
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="CcpaWrapper">
        <Container fluid>
          <Row>
            <Col className="privacypolicyText">
              <ul className="" style={{listStyle:"None"}}>
                <li>
                  <p className="col-sm-12 text-right"><NavLink to="/privacy-policy">Privacy Policy</NavLink></p>
                  <p className="h2">Addendum 1 on 23rd March 2021</p>
                  <p className="h2">Your California Privacy Rights</p>
                  <span>
                    <p>
                    Under the California Consumer Privacy Act (“CCPA”) and other California laws, California residents may have the following additional rights, subject to your submission of an appropriately verified request. (see below for verification requirements):
                    </p>
                  </span>
                </li>
                <li>
                  <p className="h2">Right to Know</p>
                  <span>
                    <p>
                    You may request the any of following, for the 12-month period preceding your request:</p>
                    <ul style={{listStyle:"decimal"}}>
                      <li>the categories of Personal Data we have collected about you, or that we have sold, or disclosed for a commercial purpose.</li>
                      <li>the categories of sources from which your Personal Data was collected.</li>
                      <li>the business or commercial purpose for which we collected or sold your Personal Data.</li>
                      <li>the categories of third parties to whom we have sold your Personal Data, or disclosed it for a business purpose. and</li>
                      <li>the specific pieces of Personal Data we have collected about you. You may send an email to <a href="mailto:privacy@onuspoint.com">privacy@OnUsPOINT.com</a> to request access to or a copy of your personal data. We may require you to provide your email address, phone number and address we have on file for you along with your desire to know what personal data we hold about you.</li>
                    </ul>
                  </span>
                </li>
                <li>
                  <p className="h2">Right to Delete</p>
                  <span>
                    <p>You have the right to delete certain Personal Data that we hold about you, subject to exceptions under applicable law. You may send an email to <a href="mailto:privacy@onuspoint.com">privacy@OnUsPOINT.com</a> you to request to have your data deleted. We may request information necessary to verify your identity, and access to your email, in order to fulfill your deletion request. Certain information may be deleted through your user account.</p>
                  </span>
                </li>
                <li>
                  <p className="h2">Right to Non-Discrimination</p>
                  <span>
                    <p>You have the right to not to receive discriminatory treatment as a result of your exercise of rights conferred by the CCPA.</p>
                  </span>
                </li>
                <li>
                  <p className="h2">Direct Marketing</p>
                  <span>
                    <p>You may request a list of Personal Data we have disclosed about you to third parties for direct marketing purposes during the preceding calendar year.</p>
                  </span>
                </li>
                <li>
                  <p className="h2">Opt-Out of Sale</p>
                  <span>
                    <p>If we engage in a "sale" of data as defined by applicable law, you have the right to opt-out of that sale. To opt-out of the sale of your personal data, send us an email to <a href="mailto:privacy@onuspoint.com">privacy@OnUsPOINT.com</a>. </p>
                  </span>
                </li>
                <li>
                  <p className="h2">Verification of Requests</p>
                  <span>
                    <p>All rights requests must be verified to ensure that the individual making the request is authorized to make that request, to reduce fraud, and to ensure the security of your Personal Data. We may require that you provide the email address we have on file for you (and verify that you can access that email account) as well as an address, phone number, or other data we have on file, in order to verify your identity. If an agent is submitting the request on your behalf, we reserve the right to validate the agent’s authority to act on your behalf. We may require you to submit rights requests directly with Clients on whose behalf we operated a given Survey. </p>
                    <p>A consumer may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</p>
                    <ul>
                      <li>Provide sufficient information that allows Panel to reasonably verify a consumer is the person about whom we collected personal information or an authorized representative.</li>
                      <li>Describe a consumer’s request with sufficient detail that allows Sample Junction to properly understand, evaluate, and respond to it.</li>
                    </ul>
                    <br />
                    <p>We do not charge a fee to process or respond to a consumer’s verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell the consumer why we made that decision and provide the consumer with a cost estimate before completing that consumer’s request.</p>
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}


Ccpa.propTypes = {
  // bla: PropTypes.string,
};

Ccpa.defaultProps = {
  // bla: 'test',
};

export default Ccpa;
