import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Button, Row, Col, Alert } from 'react-bootstrap'
//import { Test } from './CommunicationPreferences.styles';
import API from "../../../shared/api";
import SharedServices from "../../../shared/Services";

import {API_PATH} from "../../App/Constants"
import { ToastContainer, toast } from 'react-toastify';

class CommunicationPreferences extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      fields:{emailPreference:0},
      opt_days: [
        {
          id:0,
          text:"Un Subscribe"
        },
        {
          id:1,
          text:"Subscribe"
        },
      ],
    };
  }
  handleChange = event => {
    const isCheckbox = event.target.type === "checkbox";
    // console.log(event.target.value)
    let new_fields = { ...this.state.fields, [event.target.name]: event.target.value}
    this.setState({fields:new_fields})
    // console.log(this.state.fields)
  };

  handleSubmit = event => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === true) {
          // go to next step
          this.props.update_preference("email",this.state.fields.emailPreference)
          // console.log(this.state.fields)
          API.post(API_PATH.update_user_preference, this.state.fields)
          .then((response) => {
              let data = response.data
              if (this.state.fields.emailPreference == 0){
                toast.warning(data.message, { autoClose: 15000, position: toast.POSITION.TOP_RIGHT });
              }
              else{
                toast.success(data.message, { autoClose: 15000, position: toast.POSITION.TOP_RIGHT });
            }
          })
          .catch((error) => {
            
              let errorData = error.response.data
              toast.error(errorData.errors, { autoClose: 15000, position: toast.POSITION.TOP_RIGHT });
              // if(errorData.hasOwnProperty('validation_error')) {
              //     let error_messages = errorData.validation_error
              //     let errors = {}
              // }
          });
      }
  };
  componentDidMount = () => {
    this.state.fields.emailPreference=this.props.preferences.email
  }

  componentWillUnmount() {
    // this.state.fields.emailPreference=this.props.preferences.email
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
}

  render () {
    
    
    return (
      <div className="CommunicationPreferencesWrapper pt-3">
        <Form onSubmit={this.handleSubmit} id="onus_login_pop_up_form">
          <Form.Group controlId="emailPreference" as={Row}>
            <Form.Label column sm="2">Email</Form.Label>
            <Col sm="5">
              <Form.Control 
                  size="md"
                  // style={{width:"50%"}}
                  as="select"
                  name="emailPreference" 
                  onChange={this.handleChange}
                  defaultValue={this.props.preferences.email}
                  required 
              >
                {this.state.opt_days.map((opt,i)=><option value={opt.id} key={i}>{opt.text}</option>)}
              </Form.Control>
            </Col>
          </Form.Group>
          <Button type="submit" className="btn btn-primary mr-2">Save</Button>
        </Form>
      </div>
    );
  }
}

CommunicationPreferences.propTypes = {
  // bla: PropTypes.string,
};

CommunicationPreferences.defaultProps = {
  // bla: 'test',
};

export default CommunicationPreferences;
