import React, { PureComponent } from 'react';
import { Container, Row, Col, Card, Button, Form, ListGroup } from 'react-bootstrap';
import Sidebar from '../Sidebar'
import PropTypes from 'prop-types';
//import { Test } from './Profile.styles';
import API from "../../../shared/api";
import {API_PATH} from "../../App/Constants"
import SharedServices from "../../../shared/Services"
import ProfileOptions from "./ProfileOptions"
// import "./Profile.scss"
class Profile extends PureComponent {
  constructor(props) {
    super(props);
    console.log(this.props.match.params)
    this.state = {
      questions:[],
      // category:this.props.match.params.category
    };
    this.getQuestions()
  }
  
  getQuestions = () => {
    API.get(API_PATH.profiling_question+this.props.match.params.category+"/", {})
    .then(res => {
      let data = res.data.data
      this.setState({
        questions:data.questions
      })
    })
    .catch(err => {
      if(err.response.status == 401) {
        SharedServices.goToHome()
      }
      
    });
  }
  componentDidMount = () => {
    
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.match.params.category !== this.props.match.params.category) {
      this.getQuestions()
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
  }

  getOptions = (que_id) => {
    console.log(que_id)
    return <ProfileOptions queId={que_id} />
  }

  render () {
    return (
      <div className="ProfileWrapper">
        <Container fluid className="border-bottom border-top">
					<Row>
						<Sidebar />
						<Col>
              <Row>
								<Col sm={12} className="text-primary font-weight-bold mt-2 mb-2">
								My Profile
								</Col>
							</Row>
              <Row className="ml-2 mr-2 mt-3 mb-2 border rounded">
                <Col className="p-0" style={{maxHeight:"450px",overflow:"auto"}}>
                  <ListGroup as="ul">
                    {
                      this.state.questions.map((que,index)=><ListGroup.Item  as="li" action variant="info" onClick={()=>this.getOptions(que.id)} className="" key={index}>{que.title}</ListGroup.Item>)
                    }
                    
                  </ListGroup>
                </Col>
                <Col>
                
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Profile.propTypes = {
  // bla: PropTypes.string,
};

Profile.defaultProps = {
  // bla: 'test',
};

export default Profile;
