/*
* @Author: pradeep kumar baranwal
* @Date:   2020-12-09 23:27:01
* @Last Modified by:   pradeep kumar baranwal
* @Last Modified time: 2020-12-11 00:20:18
*/
import React from 'react'
import Header from '../../../Header'
import Footer from '../../../Footer'
import backImage from '../../../../assets/img/Background.jpeg'
import { Container, Row, Col, Card } from 'react-bootstrap';

var sectionStyle = {
    // backgroundColor: '#12343b',
    backgroundImage: `url(${backImage})`,
    minHeight: '430px'
}
export default class ThanksForJoining extends React.Component {
    render() {
        return (
            <div className="Signup" >
                <Header />
                <Container fluid  style={sectionStyle}>
                	<Container className="pt-5">
		                <div className="d-block pt-5 pb-5">
		                	<Row>
                            	<Col className="m-auto">
                            		<Card className="shadow">
	                            		<Card.Body>
						                	<h1 className="col text-info"> 
						                		Congratulations!!!! You have completed registration. 
						                		<br />See your email-Id for verification Link
						                	</h1>
				                		</Card.Body>
				                	</Card>
				                </Col>
			                </Row>
		                </div>
                	</Container>
                </Container>
                <Footer />
            </div>
        )
    }
}
