import React, { Component } from "react";
import axios from "axios";
import { API_BASE_URL } from "../App/Constants";

class ContactUS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      contact: "",
      message: "",
      nameError: "",
      contactError: "",
      emailError: "",
      messageError: "",
      successAlert: null,
    };
  }

  handleData = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // conditions

    if (this.state.name === "") {
      this.setState({
        nameError: "Please provide your name",
      });
    } else {
      this.setState({
        nameError: "",
      });
    }

    if (this.state.email === "") {
      this.setState({
        emailError: "Please provide an email address",
      });
    } else if (
      this.state.email === /^((?!@).)*$/ ||
      this.state.email.indexOf(".") === -1
    ) {
      this.setState({
        emailError: "Please provide a valid email address",
      });
    } else {
      this.setState({
        emailError: "",
      });
    }

    if (this.state.contact === "") {
      this.setState({
        contactError: "Please provide your Contact Number",
      });
    } else {
      this.setState({
        contactError: "",
      });
    }

    if (this.state.message === "") {
      this.setState({
        messageError: "Please leave a message",
      });
    } else {
      this.setState({
        messageError: "",
      });
    }
    setTimeout(() => {
      if (
        this.state.nameError === "" &&
        this.state.emailError === "" &&
        this.state.contactError === "" &&
        this.state.messageError === ""
      ) {
        // POST/////

        axios
          .post(API_BASE_URL + "contactus/", {
            name: this.state.name,
            email: this.state.email,
            contact: this.state.contact,
            message: this.state.message,
          })
          .then((response) => {
            if (response.data.success) {
              this.setState({
                successAlert: true,
                resData: response.data,
              });
            } else {
              this.setState({
                successAlert: false,
                name: "",
                email: "",
                contact: "",
                message: "",
              });
            }
          })
          .catch(function (error) {
            this.setState({
              successAlert: false,
            });
          });
      } else {
        this.setState({
          resData: "Oops! Something went wrong!",
        });
      }
    });
  };

  render() {
    const { name, email, contact, message } = this.state;
    return (
      <div>
        {/* Border */}
        <div style={{ borderBottom: " solid #f2f2f2" }}></div>
        <br></br>

        <div className="container">
          <h2 className="text-center">ContactUS</h2>

          <form onSubmit={this.handleSubmit}>
            {this.state.successAlert === false && (
              <div className="alert alert-success" role="alert">
                Thank You for contacting us. We will reach you soon.
              </div>
            )}

            {this.state.successAlert === true && (
              <div className="alert alert-danger" role="alert">
                Error Try again
              </div>
            )}

            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                value={name}
                className="form-control"
                name="name"
                placeholder="Name"
                onChange={this.handleData}
              ></input>{" "}
              <div className="text-danger"> {this.state.nameError} </div>
            </div>

            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                value={email}
                className="form-control"
                name="email"
                placeholder="Email"
                onChange={this.handleData}
              ></input>
              <div className="text-danger"> {this.state.emailError} </div>
            </div>

            <div className="form-group">
              <label htmlFor="phone">Contact Number</label>
              <input
                type="phone"
                value={contact}
                className="form-control"
                name="contact"
                placeholder="Contact Number"
                onChange={this.handleData}
              ></input>
              <div className="text-danger"> {this.state.contactError} </div>
            </div>

            <div className="form-group">
              <label htmlFor="desc">Message</label>
              <textarea
                value={message}
                className="form-control"
                rows="3"
                name="message"
                onChange={this.handleData}
              ></textarea>
              <div className="text-danger"> {this.state.messageError} </div>
            </div>
            <button className="btn btn-primary" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default ContactUS;
