import React, { PureComponent } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import {API_PATH} from "../../App/Constants"
import API from "../../../shared/api";
import PropTypes from 'prop-types';
import SharedServices from "../../../shared/Services"
import { toast } from 'react-toastify';
//import { Test } from './VerifyEmail.styles';

class VerifyEmail extends PureComponent { 
  constructor(props) {
    super(props);
    const verify_button = <Button onClick={()=>this.doEmailVerify()}>Verify email</Button>;
    this.state = {
      server_verify:false,
      logged_in_user:{is_email_verified:false},
      sent_message :verify_button,
      verify_button : verify_button,
    };
  }

  componentDidMount = () => {
    API.get(API_PATH.sent_verification_email, {})
    .then(res => {
      this.setState({
        server_verify:true,
        logged_in_user : res.data.user,
      })
      localStorage.setItem("_o_u",JSON.stringify(res.data.user))
    })
    .catch(err => {
      if(err.response.status == 401) {
        SharedServices.goToHome()
      }
    });
    
  }
  doEmailVerify = () => {
    this.setState({sent_message:<span className="text-info">Processing...</span>});
    API.post(API_PATH.sent_verification_email, {email:this.state.logged_in_user.email})
    .then(res => {
      this.setState({
        logged_in_user : res.data.user
      });
      localStorage.setItem("_o_u",JSON.stringify(res.data.user))
      if(res.status == 202) {
        toast.success("Mail sent to your registered email.", { autoClose: 15000, position: toast.POSITION.TOP_RIGHT });
        this.setState({
          sent_message :<>Mail sent to your registered email address!.</>,
        })
      }
    })
    .catch(err => {
      if(err.response.status == 401) {
        SharedServices.goToHome()
      }
      else {
        this.setState({sent_message:this.state.verify_button});
        toast.error("Please try again later!", { autoClose: 15000, position: toast.POSITION.TOP_RIGHT })
      }
    });
  };
  componentWillUnmount() {
      // fix Warning: Can't perform a React state update on an unmounted component
      this.setState = (state,callback)=>{
          return;
      };
  }
  render () {
		if (!this.state.server_verify || this.state.logged_in_user.is_email_verified) {
			return (<></>);
		}
    return (
      <div className="VerifyEmailWrapper">
        <Row>
          <Col className="border rounded ml-3 mr-3 mb-3">
            <p className="font-weight-bold text-danger" style={{fontSize:"25px"}}>
            Your email address should be verified in order to receive your joining bonus and points on survey completion.
            </p>
            <p className="text-center">
              {this.state.sent_message}
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

VerifyEmail.propTypes = {
  // bla: PropTypes.string,
};

VerifyEmail.defaultProps = {
  // bla: 'test',
};

export default VerifyEmail;
