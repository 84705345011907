import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
//import { Test } from './MyWallet.styles';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useHistory } from 'react-router-dom'
import API from "../../../shared/api";
import {API_PATH} from "../../App/Constants"
import SharedServices from "../../../shared/Services"

class MyWallet extends PureComponent { 
  constructor(props) {
    super(props);
    
    this.state = {
      wallet:{}
    };
  }
  componentDidMount = () => {
    API.get(API_PATH.redeemption_history + "?type=my_wallet", {})
    .then(res => {
      let data = res.data.data
      this.setState({
        wallet:{
          minPointToCash: data.minPointToCash,
          confirmPoints:data.confirmPoints,
          pendigConfirmPoints:data.pendigConfirmPoints,
        }
      })
    })
    .catch(err => {
      if(err.response.status == 401) {
        SharedServices.goToHome()
      }
      
    });
  }
  
  cahout = () => {
    
  }

  componentWillUnmount() {
      // fix Warning: Can't perform a React state update on an unmounted component
      this.setState = (state,callback)=>{
          return;
      };
  }

  render () {
    let cash_out = (this.state.wallet.minPointToCash - this.state.wallet.confirmPoints) <= 0 ? false : true;
    
    return (
      <div className="MyWalletWrapper">
        <Row>
          <Col sm={12}>
            <p className="font-weight-bold" style={{color:"#0fbf0f",fontSize:"18px"}}>My Wallet</p>
          </Col>
          <Col>
            <Row className="pb-2 text-center">
              <Col md={6} className="border-right pt-5">
                <h3>{this.state.wallet.confirmPoints} Available Points</h3>
                
                <h5 className="text-secondary pt-2">{this.state.wallet.pendigConfirmPoints} points pending</h5>
                <p className="pt-2">
                {(this.state.wallet.minPointToCash > this.state.wallet.confirmPoints) ? <> {(this.state.wallet.minPointToCash - this.state.wallet.confirmPoints)} points required to redeem</> : <> You have total {this.state.wallet.confirmPoints} points</>} 
                </p>
              </Col>
              <Col md="1"></Col>
              <Col className="text-center">
                  <div className="p-md-4 p-sm-5">
                    <CircularProgressbar value={this.state.wallet.confirmPoints} maxValue={this.state.wallet.minPointToCash} text={`${this.state.wallet.confirmPoints}`} strokeWidth="8" />
                  </div>
                <Button className="mt-2" onClick={() => window.location.assign('/rewards')} title={`A minimum of ${this.state.wallet.minPointToCash} points are required to redeem`} disabled={cash_out}>Redeem</Button>
                
              </Col>
              <Col md="1"></Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

MyWallet.propTypes = {
  // bla: PropTypes.string,
};

MyWallet.defaultProps = {
  // bla: 'test',
};

export default MyWallet;
