import React from 'react'
import logo from '../../assets/img/logo.png'
// import pass from '../../assets/img/Paas.png'
import './Footer.scss'
import { NavLink } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap';
import {API_PATH} from "../App/Constants"
import SharedServices from "../../shared/Services"

function Footer() {
    const logged_in = localStorage.getItem(API_PATH.token_name) ? true : false;
    let isExcluded = SharedServices.excludeHeaderAndFooter()
    if(isExcluded) {
      return <></>
    }
    return (
        <div className="Footer">
            <Container>
                <Row className="align-items-center text-center" >
                    <Col sm={4}>
                        <div className="ft-about">
                            <img src={logo} alt="" />
                            <p>When you receive an email invite, just click the link to participate in the activity and answer the questions we have for you.  </p>
                        </div>
                    </Col>
                    <Col sm={4} className="pb-4">
                            
                    </Col>
                    <Col sm={4} className="pt-sm-2">
                        <div className="ft-list">
                            <ul className="text-left">
                                <li><NavLink to="/">HOME</NavLink></li>
                                {logged_in ? <></> : <li><NavLink to="/registration/">JOIN US</NavLink></li>}
                                
                                <li><NavLink to ="/our-mission">OUR MISSION</NavLink></li>
                                <li><NavLink to="/contactus">CONTACT US</NavLink></li>
                                <li><NavLink to="/privacy-policy">PRIVACY POLICY</NavLink></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Footer;