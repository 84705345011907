/*
* @Author: pradeep kumar baranwal
* @Date:   2020-12-10 02:46:06
* @Last Modified by:   pradeep kumar baranwal
* @Last Modified time: 2020-12-22 15:56:34
*/
import React from 'react';
import { Container, Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import API from "../../../shared/api";
import {API_PATH} from "../../App/Constants"
export default class ResetPassword extends React.Component {
  _isMounted = false;
	constructor(props) {
      super(props);
      this.state = {
          fields:{
            password:"",
            confirm_password:"",
          },
          validated:false,
          validToken:false,
          invalidTokenStr:"Loading...",
          errors:{},
          changedPassword:false,
          show:false,
      };
      this.handleChange = this.handleChange.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount = () => {
    this._isMounted = true;
    const { key } = this.props.match.params;
    API.post(API_PATH.validate_token ,{token: key}           
      ).then((response) => {
        if (this._isMounted) {
          this.setState({
            validToken : true
          });
        }
      }).catch( (error) => {
        this.setState({invalidTokenStr:error.response.data.status});
      })
      
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleChange = (event) => {
      // event.preventDefault();
      let fields  = this.state.fields;
      const isCheckbox = event.target.type === "checkbox";
      fields[event.target.name] = isCheckbox ? event.target.checked : event.target.value;
      // this.setState({
      //   fields
      // });
  };
  handleSubmit = event => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      let data = this.state.fields
      data['token'] = this.props.match.params.key
      API.post(API_PATH.confirm_token ,data
      ).then((response) => {
          // console.log("RESPONSE RECEIVED: ", response);
          this.setState({
            changedPassword :true
          });
      }).catch( (error) => {
        this.setShow(true);
        this.setState({errors:error.response.data});
      })
        return true;

    }
    this.setState({ validated: true });
    return false;
  };

  notValidTokenBlock = () => {
    return (
      <Row>
        <Col className="text-center">{this.state.invalidTokenStr}</Col>
      </Row>
    )
  };

  successConfirm = () => {
    return (
      <Row>
        <Col className="text-center"> Password has been changed successfully.</Col>
      </Row>
    )
  };
  setShow = (show) => {
    this.setState({show:show});
  };
  resetPasswordBlock = () => {
    let alertBox = ''
    if (this.state.show) {
      alertBox = (
        <Alert variant="danger" onClose={() => this.setShow(false)} dismissible>
          {this.state.errors.status}
        </Alert>
      );
    }
    return (
      <Form
        onSubmit={this.handleSubmit}
        style={{lineHeight: "1rem" }}
        noValidate 
        validated={this.state.validated}
      >
        {alertBox}
        <Form.Group as={Row}>
          <Col>
            <Form.Label htmlFor="password">New Password</Form.Label>
            <Form.Control 
              required
              size="sm"
              name="password"
              id="password"
              type="password"
              minLength="4"
              maxLength="100"
              placeholder="Enter Password"
              defaultValue={this.state.fields.password}
              onChange={this.handleChange}
            />
            <Form.Control.Feedback type="invalid">
              This field is required.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col>
            <Form.Label htmlFor="confirm_password">Confirm Password</Form.Label>

            <Form.Control 
              required
              size="sm"
              name="confirm_password"
              id="confirm_password"
              type="password"
              minLength="4"
              maxLength="100"
              placeholder="Enter Confirm Password"
              defaultValue={this.state.fields.confirm_password}
              onChange={this.handleChange}
            />
            <Form.Control.Feedback type="invalid">
              This field is required.
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Row className="pt-2">
            <Col sm={12} className="text-center">
                <Button type="submit" className="btn btn-primary m-auto">Submit</Button>
            </Col>
        </Row>
        
      </Form>
    )
  }


  render() {
    let content = this.resetPasswordBlock()
    if(!this.state.validToken && !this.state.changedPassword) {
      content = this.notValidTokenBlock()
    }
    else if (this.state.changedPassword) {
      content = this.successConfirm()
    }
  	return (
	  	<div className="ForgotPassword">
		    <Container fluid >
        	<Container className="d-block pt-5 pb-5">
          	<Row>
              <Col sm={8}  className="m-auto">
                <Card className="shadow">
                  <Card.Header className="text-center text-light font-weight-bolder bg-primary">Reset Password</Card.Header>
                  <Card.Body>
                    {content}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
        	</Container>
        </Container>
		</div>
	  )	
  }  
}