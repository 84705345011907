import React, { PureComponent } from 'react';
import { Container, Row, Col, Card, Button, Form, Table } from 'react-bootstrap';
import {FaDesktop, FaMobileAlt, FaTabletAlt, FaArrowAltCircleRight } from "react-icons/fa";
import {API_PATH} from "../../App/Constants"
import API from "../../../shared/api";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import SharedServices from "../../../shared/Services"
//import { Test } from './HotSurveys.styles';
import { css } from "@emotion/react";
import LoadingOverlay from 'react-loading-overlay'
import {ClockLoader} from "react-spinners";
import backImage from '../../../assets/img/Background.jpeg'

//import { Test } from './MySurveys.styles';

var sectionStyle = {
  // backgroundColor: '#82bb41',
  backgroundImage: `url(${backImage})`,
  
}

const override = css`
display: block;
margin: 0 auto;
border-color: red;
overflow:hidden;
`;

class HotSurveys extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      loading:false,
      survey_data : [],
      calling_remote:true,
      devices_map:{"desktop":<FaDesktop title="Available on Desktop!" key="desktop" />,"tablet":<FaTabletAlt title="Available on Tablet!" key="tablet" />,"phone":<FaMobileAlt title="Available on Mobile!" key="phone" />},
    };
  }
  componentDidMount = () => {
    API.get(API_PATH.survey+"?type=dashboard", {})
    .then(res => {
      if(res.data.data.surveys.length>0) {
        this.setState({
          survey_data : res.data.data.surveys,
        })
      }
      this.setState({calling_remote:false,})
    })
    .catch(err => {
      if(err.response.status == 401) {
        SharedServices.goToHome()
      }
      this.setState({calling_remote:false,})
    });
  };
  goToSurvey=(ev,survey_id)=>{
    this.setState({loading:true});
    SharedServices.goToSurvey(survey_id);
    setTimeout(() => this.setState({ loading: false }),4000);
  }
  
  render () {
    let survey_list = <Link className="btn btn-sm btn-primary" to="/my-surveys">View All Surveys</Link>
    if(this.state.calling_remote) {
      survey_list =  <Col className="text-center font-weight-bold"><p className="text-info">Fetching surveys...{' '}</p></Col>;
    }
    else {
      if(this.state.survey_data.length <= 0) {
        survey_list = <div className="text-center text-info">No Survey Found</div>;
      }
    }
    return (
      <div className="HotSurveysWrapper">
        <Container fluid className="pl-0 pr-0">
        <LoadingOverlay
            active={this.state.loading}
            spinner={<ClockLoader
                css={override}
                size={150}
                color={"#123abc"}
                
              />}
          />
          <Table responsive size="md">
          <thead>
            <tr>
              <th>Survey</th>
              <th>Device</th>
              <th>Available Since</th>
              <th>Mins</th>
              <th>Points</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
              {this.state.survey_data.map((survey,i)=>
              <tr key={i}>
                <td>{survey.id}</td>
                <td>{survey.devices.map((d,j)=>this.state.devices_map[d])}</td>
                <td>{survey.live_since}</td>
                <td>{survey.loi}</td>
                <td>{survey.points}</td>
                <td><Button variant="default" className="text-primary" onClick={(ev)=>this.goToSurvey(ev,survey.id)} size="sm">Take Survey</Button></td>
              </tr>
              )}
              <tr>
                <td colSpan="6" className="text-right">{survey_list}</td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </div>
    );
  }
}

HotSurveys.propTypes = {
  // bla: PropTypes.string,
};

HotSurveys.defaultProps = {
  // bla: 'test',
};

export default HotSurveys;
