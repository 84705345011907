import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import "./PrivacyPolicy.scss"
//import { Test } from './PrivacyPolicy.styles';
class PrivacyPolicy extends React.Component {
  componentDidMount = () =>{
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="PrivacyPolicyWrapper">
        <Container fluid>
          <table className="table col-sm-12 col-md-5 col-lg-3  table-md text-info table-borderless">
            <tr>
              <td>Effective From</td>
              <td>:</td>
              <td>Feb,2020</td>
            </tr>
            <tr>
              <td>Last Updated</td>
              <td>:</td>
              <td>Mar,2021</td>
            </tr>
          </table>
          <Row>
            <Col className="privacypolicyText">
              <ul className="" style={{listStyle:"decimal"}}>
                <li>
                  <p className="h2">
                  Introduction
                  </p>
                  <span>
                    <p>
                    This Privacy Policy sets out the commitment to the privacy of its panel members (“Panelists”, “Panelist”), and governs Panelists’ rights regarding privacy and data protection. This Privacy Policy applies to the information you provide to us when you visit <NavLink to="//www.onuspoint.com">www.OnUspoint.com</NavLink> ("Website") or participate in any OnUs POINT panel or subpanel (“Panel”). 
                    </p>
                    <p>
                    Taking part in our panels, surveys and research activities is entirely voluntary and therefore processing of your Personal Data in connection with the services and this Privacy Policy is with your consent. Panelists are members of the Panel, operated by OnUs POINT as the data controller. All references to “us” or “we” refer to OnUs POINT. 
                    </p>
                    <p>
                    By using this Website or any of the services offered on or through the Website, you are agreeing that you have read, understand, and accept the practices described in this Privacy Policy. We ask you to read this Privacy Policy carefully.
                    </p>
                    <p>
                    For the purpose of this Privacy Policy, “Personal Data” means any information which relates to an identifiable living individual.
                    </p>
                  </span>
                </li>
                
                <li>
                  <p className="h2">
                    Collection and processing of Personal Data
                  </p>
                  <span>
                  <p>
                    OnUs POINT collects personal information from different sources like websites, mobile application and other panelist activities such as social media, apps and surveys. Some examples are:
                    
                    <ul>
                      <li>Registration: some personal data is gathered when you register, such as name, email address, postal address, telephone number etc</li>
                      <li>Panel surveys or research activities: personal data may also be collected when you participate in a survey or in any activity, such as use of a specific app, passive tracking or social tracking.</li>
                      <li>Account profile: you may decide to share your mobile number or your social network ID/account with us or you may provide other personal data when setting up your account or at a later stage.</li>
                    </ul>
                  </p>
                  <p> 
                    OnUs POINT also collects anonymous demographic information, which is not unique to you, such as your ZIP code, age, gender, preferences, interests and favorites.
                  </p>
                  <p>
                    There is also information about your computer hardware and software that is automatically collected by OnUs POINT such as your IP address, browser type, domain names, access times and referring Web site addresses. This information is used by OnUs POINT for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the OnUs POINT web site.
                  </p>
                  <p>
                    We are also required by law to explain the legal basis for processing your personal data. These legal bases are listed below and could be different for each use case:
                    <ul>
                      <li>we have your consent for the use of your personal data</li>
                      <li>we need to use your personal data in order to perform a contract with you</li>
                      <li>we need to process your data to comply with legal obligation(s)</li>
                      <li>we need to process your data in order to protect your vital interests </li>
                      <li>the processing is necessary to perform a task in the public interest </li>
                      <li>the use of your personal data is necessary for our (or our clients’) legitimate business interests </li>
                    </ul>
                  </p>
                  <p>
                    Do note that if you directly disclose personally identifiable information or personally sensitive data through OnUs POINT public message boards, this information may be collected and used by others. 
                  </p>
                  <p>
                    OnUs POINT encourages and recommends you to review the privacy statements of websites you choose to link to from OnUs POINT so that you can understand how those web sites collect, use and share your information. OnUs POINT is not responsible for the privacy statements or other content on websites outside of the OnUs POINT and OnUs POINT family of websites.
                  </p>
                  <p>
                    If you receive an email that concerns you, please let us know as shown below in ‘Contact Us’.
                  </p>
                  </span>
                </li>
                <li>
                  <p className="h2">Use of Personal Data</p>
                  <span>
                    <p>
                      The main purposes for which we use your personal Information are:
                      <ul>
                        <li>
                        Contact you for surveys via email, mobile notifications or texts and other communication options to inform you of other products or services available from OnUs POINT and its affiliates.
                        </li>
                        <li>Inform you of updates to our panel services, new features and details relevant to you through panel communications</li>
                        <li>Help you when you contact our support team or arrange for deliveries.</li>
                        <li>Allow us to reward you with the promised incentives or include you in our prize draws</li>
                        <li>Update, enrich and clean our panel members’ data to improve our usage of data, allowing us to better map you for surveys</li>
                        <li>Contact you on behalf of external partners about a particular offering that may be of interest to you</li>
                        <li>OnUs POINT may share data with trusted partners to help us perform statistical analysis </li>
                        <li>OnUs POINT keeps track of the Website and pages our customers visit within OnUs POINT, to determine what OnUs POINT services are the most popular.</li>
                        <li>This data is used to deliver customized content and advertising within OnUs POINT to customers whose behavior indicates that they are interested in a particular subject area. </li>
                      </ul>
                    </p>
                    <p>
                      OnUs POINT Website will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: 
                      <ul>
                        <li>conform to the edicts of the law or comply with legal process served on OnUs POINT or the site; </li>
                        <li>protect and defend the rights or property of OnUs POINT; and, </li>
                        <li>act under exigent circumstances to protect the personal safety of users of OnUs POINT, or the public.</li>
                      </ul>
                    </p>
                  </span>
                </li>
                <li>
                  <p className="h2">Storing Personal Data</p>
                  <span>
                    <p>OnUs POINT’s data storage servers are in Europe and/or in the United States of America (USA) and are managed by third party service providers in the cloud.</p>
                    <p>We take appropriate technological and organizational measures to protect your personal data, both during transmission and once we receive it. Our security procedures are consistent with generally accepted standards used to protect personal data.</p>
                  </span>
                </li>
                <li>
                  <p className="h2">Security measures and Confidentiality </p>
                  <span>
                    <p>OnUs POINT takes steps to ensure that your information is treated securely and in accordance with this Privacy Policy. Unfortunately, no data transmission can be guaranteed to be 100% secure. As a result, while we strive to protect your personal data, OnUs POINT cannot ensure or warrant the security of any information you transmit to us or from our online products or services, and you do so at your own risk. Once we receive your transmission, we will take reasonable steps to ensure our systems are secure.</p>
                    <p>Ultimately, you are solely responsible for maintaining the secrecy of your passwords and/or any account information. OnUs POINT will never ask you for your password in an unsolicited phone call or in an unsolicited email. Please be careful and responsible whenever you're online.</p>
                    <p>Also, please remember to sign out of your Panels account and close your browser window when you have finished visiting our site. This is to ensure that others cannot access your personal data and correspondence if you share a computer with someone else or are using a computer in a public place like a library or Internet cafe. Please change your password regularly.</p>
                  </span>
                </li>
                <li>
                  <p className="h2">Sharing </p>
                  <span>
                    <p>We may need to disclose your personal information when we have a good-faith belief that such action is necessary 
                      <ul>
                        <li>To comply with a judicial proceeding or a court order </li>
                        <li>If we believe it is necessary to investigate, prevent or take action regarding illegal activities </li>
                        <li>Suspected fraud, situations involving potential threats to the physical safety of any person, </li>
                        <li>Violations of the OnUs POINT site terms </li>
                        <li>as otherwise required by law.</li>
                      </ul>
                    </p>
                    <p>OnUs POINT may also disclose your personal information to third parties where it has your express permission to do so, or where it can reasonably be inferred from the circumstances that you consent to the disclosure to the third parties. Changes to this Statement OnUs POINT will occasionally update this Statement of Privacy to reflect company and customer feedback.</p>
                    <p>OnUs POINT may share your personal information and/or social-demographic information, including, without limitation, a unique identification number (“UID”), postal code, gender, marital status, education, ethnicity/race (where permitted by applicable law), employment related information, and non-personally identifiable information on household members, with third party sample/market research companies (individually each a “Third Party MR Company” and collectively the “Third Party MR Companies”) for the following purposes:
                      <ul>
                        <li>Identifying client survey opportunities that you may be eligible for through the Third-Party MR Companies.</li>
                        <li>Disclosing such data elements to clients of the Third-Party MR Companies for audience measurement services.</li>
                        <li>Appending such data elements to completed client surveys and delivering the appended survey data to clients of the Third-Party MR Company(ies) for analytical or research purposes. </li>
                      </ul>
                    </p>
                    <p>If you are eligible for a client survey opportunity offered through a Third Party MR Company, the Third Party MR Company will provide your unique identification number and a survey link to us and we will invite you to participate in the client survey through email. We will not share your name, email address, or phone number with the Third-Party MR Companies. Your participation in such survey opportunities does not entitle you to any benefits offered by, or membership with, the Third-Party MR Companies. </p>
                    <p>In connection with the sharing of your information with a Third Party MR Company, your information may be transferred and stored outside of your country of residence, including, without limitation, in the United States or Europe , and by agreeing to this privacy policy you are agreeing to the processing and exportation of your personal information as set forth in this document.</p>
                    <p>If you receive an email that concerns you, please let us know as shown below in ‘Contact Us’.</p>
                  </span>
                </li>
                <li>
                  <p className="h2">Cookie Disclosure</p>
                  <span>
                    <p>The OnUs POINT Website use "cookies" to help you personalize your online experience. Cookies are small text files stored on your computer or mobile device by a website that assigns a numerical user ID and stores certain information about your online browsing. They are used to help users navigate websites efficiently and perform certain functions. The website sends information to the browser which then creates a text file on the user’s computer or mobile device. Every time the user goes back to the same website, the browser retrieves and sends this file to the website's server.</p>
                    <p>This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same OnUs POINT website, the information you previously provided can be retrieved, so you can easily use the OnUs POINT features that you customized.</p>
                    <p>You can accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the OnUs POINT services or Web sites you visit.</p>
                    <p>We may also use your IP address to check whether there have been multiple participations in the survey from this IP address and also to protect our business against fraudulent behaviour.</p>
                    <p>As you use the Internet, a trail of electronic information is left at each website you visit. This information, which is sometimes referred to as ‘clickstream data’, may be collected and stored by a website's server. Clickstream data can tell us the type of computer and browsing software you use and the address of the web site from which you linked to the Site. We may collect and use clickstream data as aggregated information to anonymously determine how much time visitors spend on each page of our site, how visitors navigate throughout the site and how we may tailor our web pages to better meet the needs of visitors. This information will be used to improve our site and our services. Any collection or use of clickstream data will be anonymous and will not intentionally contain any personal data.</p>
                    <p>For more information, please log in and access your cookie preferences page where you are able to adjust your cookie settings.</p>
                  </span>
                </li>
                <li>
                  <p className="h2">Additional addendum to privacy policy </p>
                  <span>
                    <ul>
                      <li><NavLink to="/privacy-policy-gdpr/">Click here</NavLink> to find more on GDPR compliance</li>
                      <li>For residents of the State of California, please <NavLink to="/privacy-policy-ccpa/">click here</NavLink> to find out more about your rights under the California Consumer Privacy Act of 2018 (“CCPA”) and our compliance.</li>
                    </ul>
                  </span>
                </li>
                <li>
                  <p className="h2">Contact US</p>
                  <span>
                    <p>We encourage you to share your questions or concerns relating to your privacy or to OnUs POINT privacy practices. You can contact OnUs POINT by email at <a href="mailto:privacy@onuspoint.com">privacy@OnUsPOINT.com</a></p>
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
PrivacyPolicy.propTypes = {
  // bla: PropTypes.string,
};

PrivacyPolicy.defaultProps = {
  // bla: 'test',
};

export default PrivacyPolicy;
