import React, {useState} from 'react'
import './Home.scss'
import Slider from './Slider'
import HowItWorks from './HowItWorks'
import BannerBar from './BannerBar'
import Dashboard from '../Restricted/Dashboard'
import {API_PATH} from "../App/Constants"
export default function Home() {
    const logged_in = localStorage.getItem(API_PATH.token_name) ? true : false;
    
    if(logged_in) {
        return (<Dashboard />)
    }
    else {
        return (
            <div className="Home">
                <Slider/>
                {/* {getHomePage} */}
                <BannerBar/>
                <HowItWorks/>
            </div>
        )
    }
}
