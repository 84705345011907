import React from 'react';
import PropTypes from 'prop-types';
//import { Test } from './NotFound.styles';
import './NotFound.scss';
import { Link } from 'react-router-dom'
import mirrorImage from  "../../assets/img/Mirror.png"
const NotFound = ({location}) => (
  <div className="notFoundBody">
    <div className="NotFoundWrapper">
        <aside><img src={mirrorImage} alt="404 Image" /></aside>
        <main>
          <h1>Sorry!</h1>
          {/* <p className="text-light">
            Either you aren't cool enough to visit this page or it doesn't exist <em>. . . like your social life.</em>
          </p> */}
          <Link to="/">Go To Home</Link>
        </main>
      {/* <h1>404 - Not Found!</h1>
      <h2>No match found for <code>{location.pathname}</code></h2>
      <Link to="/">
        Go Home
      </Link> */}
    </div>
  </div>
);

export default NotFound;
