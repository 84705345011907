import React, { Component } from 'react'
import { Form, Modal, Button, Row, Col, Alert } from 'react-bootstrap'
import './LoginForm.scss'
import API from "../../../shared/api";
import { NavLink } from 'react-router-dom'
import {API_PATH} from "../../App/Constants"


export default class LoginForm extends Component {
    constructor(props) {
        super(props);
        // localStorage.removeItem(API_PATH.token_name);
        this.state = {
            emailInput: "",
            passwordInput: "",
            isLoading: false,
            errors: "",
            showModel:false,
            showAlert:false,
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.onPressLogin = this.onPressLogin.bind(this)
    }

    handleChange = event => {
        const isCheckbox = event.target.type === "checkbox";
        event.target.value = event.target.value.trimLeft()
        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        });
    };

    handleSubmit = event => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            // go to next step
            this.onPressLogin();
        }
    };
    onPressLogin() {
        let payload = { email: this.state.emailInput, password: this.state.passwordInput };
        document.getElementById("onus_login_pop_up_form").reset()
        const onSuccess = ({ data }) => {
            // Set JSON Web Token on success
            this.setState({ isLoading: false });
            localStorage.setItem(API_PATH.token_name, data.access)
            localStorage.setItem(API_PATH.refresh_token_name, data.refresh)
            localStorage.setItem("_o_u", JSON.stringify(data.user));
            window.location.replace("/");
        };

        const onFailure = (error) => {
            let err = error.response.data.detail;
            this.setShow(true)
            this.setState({ isLoading: false, errors: err });
        };


        API.post(API_PATH.login, payload)
            .then(onSuccess)
            .catch(onFailure);
    }
    setShow = (show) => {
        this.setState({showAlert:show});
    };
    render() {
        // const { isLoading } = this.state.isLoading;
        let reset = () => {
            this.setState({showModel:false});
        };
        let alertBox = ''
        if(this.state.showAlert) {
            alertBox = (
                <Alert variant="danger" onClose={() => this.setShow(false)} dismissible>
                  {this.state.errors}
                </Alert>
            )
        }

        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                onHide={reset}
            >
                <div className="modal-header">
                    <div className="modal-title h4" id="contained-modal-title-vcenter">Login</div>
                    <button type="button" className="close"  onClick={this.props.onHide}>
                        <span aria-hidden="true">×</span><span className="sr-only">Close</span>
                    </button>
                </div>
                
                <Modal.Body>
                    {alertBox}
                    <Form onSubmit={this.handleSubmit} id="onus_login_pop_up_form">
                        <Form.Group controlId="emailInput">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control 
                                type="email" 
                                name="emailInput" 
                                // defaultValue={this.state.emailInput} 
                                placeholder="Enter email" 
                                onChange={this.handleChange} 
                                required 
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid email.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="passwordInput">
                            <Form.Label>Password</Form.Label>
                            <Form.Control 
                                required
                                type="password" 
                                name="passwordInput" 
                                // defaultValue={this.state.passwordInput} 
                                placeholder="Password" 
                                onChange={this.handleChange} 
                            />
                        </Form.Group>
                        <Button type="submit" className="btn btn-primary w-100">Login</Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="pt-0">
                    <Col as="ul" className="text-left">
                        <li style={{listStyle:"None"}} onClick={this.props.onHide}><NavLink to="/registration/">Join Now</NavLink></li>
                    </Col>
                    <Col as="ul" className="text-right">
                        <li style={{listStyle:"None"}} onClick={this.props.onHide}><NavLink to="/user/forgot-password/">Forgot Your Password?</NavLink></li>
                    </Col>
                </Modal.Footer>
            </Modal>
        );

    }
}

