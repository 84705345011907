import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Form, Row, Button, Card } from 'react-bootstrap';
import { FaRegFrown } from 'react-icons/fa';
import API from "../../../shared/api";
import {API_PATH} from "../../App/Constants"
import queryString from "query-string";
//import { Test } from './UnsubscribeEmail.styles';
import { ToastContainer, toast } from 'react-toastify';

class UnsubscribeEmail extends PureComponent { 
  constructor(props) {
    super(props);
    const { encid }  = queryString.parse(this.props.location.search)
    this.state = {
      token : encid
    }
  }

  erroMessages = (messages) => {
    let msg = [];
    for (let i = 0; i < messages.length; i++) {
      msg.push(messages[i]['message']);
    }
    return msg.join(".");
  };

  handleChange = event => {
    this.setState({[event.target.name]: event.target.value.trimLeft()})
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      // go to next step
      API.post(API_PATH.unsubscribe_email, {data: {comment:this.state.usercomment,enc_id:this.state.token}})
      .then((response) => {
          toast.success(response.data.message, {position: toast.POSITION.TOP_RIGHT });
          
        
      })
      
      .catch((err) => {
          if(Object.keys(err.response.data).length > 0 && err.response.data.validation_error) {
            let validation_error = err.response.data.validation_error;
            let errors = []
            Object.entries(validation_error).forEach(([key, value]) => errors.push(key + "-" + this.erroMessages(value)));
            toast.error(errors.join(", "), { position: toast.POSITION.TOP_RIGHT });
          }
          else {
            toast.error("Please try again later!!", {position: toast.POSITION.TOP_RIGHT });
          }
      });
    }
  }
  componentWillUnmount = () => {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  gotoHome = () =>{
    window.location.replace("/")
  }
  render () {
    return (
      <div className="UnsubscribeEmailWrapper">
        <Container className="pt-5">
          <Card>
            <Card.Header className="text-primary">
              <div className="mt-3">
                <p className="h1 text-underline">Unsubscribe</p>
              </div>
              
            </Card.Header>
            <Card.Body>
            {this.state.token ?
              (<span><h3>We are sorry to see you Unsubscribed!!! <FaRegFrown className="text-warning" /></h3>
              <p>
                <small>
                Your unsubscription request was taken into account and will be effective within the next 24hrs.
                </small>
              </p>
              
              <Form onSubmit={this.handleSubmit} id="onus_login_pop_up_form pt-3">
                <Form.Group controlId="usercomment">
                  <Form.Label>Comment</Form.Label>
                  <Form.Control 
                    as="textarea"
                    name="usercomment" 
                    rows="6"
                    maxLength={500}
                    onChange={this.handleChange} 
                    required
                    
                  />
                </Form.Group>
                <Button type="submit" className="btn btn-primary mr-2">Submit</Button>
                <Button type="reset" className="btn btn-light" onClick={()=>this.gotoHome()}>Cancel</Button>
              </Form></span>) : (<Row><Col>Incorrect Url.Please close this window and try again.</Col></Row>)
              }
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

UnsubscribeEmail.propTypes = {
  // bla: PropTypes.string,
};

UnsubscribeEmail.defaultProps = {
  // bla: 'test',
};

export default UnsubscribeEmail;
